// @ts-check
import React, { useDeferredValue, useMemo } from "react"
import { ActiveIndicatorIcon, BlickeSvg } from "../../svgs"
import {
  Box,
  Text,
  Stack,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Portal,
  useDisclosure,
  PopoverAnchor,
  useBreakpointValue,
} from "@chakra-ui/react"
import { BereicheMenu, BlickeMenu, DatenProjekteMenu } from "./submenus"
import { NAV_ITEMS } from "../../Navbar"
import { desktopNavBgColor } from "./submenus/desktop-nav-constants"

/**
 * Represents the desktop navigation.
 * @param {object} props
 * @param {object} props.data - The data object.
 * @param {string} props.pageType - The type of the page.
 */
const DesktopNav = ({ data, pageType }) => {
  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem, index) => (
        <NavItem
          key={index}
          navItem={navItem}
          pageType={pageType}
          data={data}
        />
      ))}
    </Stack>
  )
}

export default DesktopNav

/**
 * Represents a navigation item.
 * constructor
 * @param {object} props
 * @param {string} props.pageType - The type of the page.
 * @param {string} props.navItem - The title of the navigation item.
 * @param {object} props.data - The data object.
 */
const NavItem = ({ pageType, navItem, data }) => {
  const { isOpen, onClose, onOpen } = useDisclosure({ defaultIsOpen: false })

  const deferredOpen = useDeferredValue(isOpen)

  const isActiveLink = pageType === navItem

  const trigger =
    useBreakpointValue({
      base: "click",
      lg: "hover",
    }) || "hover"

  return (
    <Box key={navItem}>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        // @ts-ignore
        trigger={trigger}
        variant="responsive"
      >
        <Portal>
          <PopoverAnchor>
            <Box
              id="navbar-anchor"
              position="absolute"
              top={0}
              left={0}
              right={0}
              width="100vw"
              height={0}
              zIndex={1000}
            />
          </PopoverAnchor>
        </Portal>

        <PopoverTrigger>
          <Link
            href="#"
            p={{ md: 0, lg: 4 }}
            position="relative"
            _hover={{
              textDecoration: "none",
            }}
            aria-label={navItem === "Blicke" ? "Blicke" : undefined}
          >
            {navItem === "Blicke" ? (
              <Box display="inline-block">
                <BlickeSvg />
              </Box>
            ) : (
              <Text
                as="span"
                fontSize="lg"
                fontFamily={isActiveLink ? "CocoSharpL-Bold" : undefined}
              >
                {navItem}
              </Text>
            )}
            <Box
              className={isActiveLink ? "nav_active" : ""}
              top={{ md: "-57px", lg: "-42px" }}
              display={isActiveLink ? "flex" : "none"}
            >
              <ActiveIndicatorIcon />
            </Box>

            <Box
              position="absolute"
              display={isOpen ? "flex" : "none"}
              opacity={deferredOpen ? 1 : 0}
              bottom={{
                md: "-44px",
                lg: "-34px",
              }}
              width="90px"
              height="100px"
              left="50%"
              zIndex={100}
              transform="translateX(-50%) rotate(180deg)"
              transition={"opacity 0.2s ease-in-out"}
            >
              <ActiveIndicatorIcon color={desktopNavBgColor} />
            </Box>
          </Link>
        </PopoverTrigger>
        <PopoverContent
          border={0}
          display={{ base: "none", md: "flex" }}
          px={4}
          w="100vw"
          sx={{
            border: "none",
            position: "absolute",
            boxShadow: "none",
            left: 0,
            right: 0,
          }}
          maxWidth="100%"
          maxW="78rem"
          left="50%"
          top={{ md: "6.2rem", lg: "6.75rem" }}
          bg="none"
          transform="translateX(-50%)!important"
        >
          <MenuSwitch data={data} type={navItem} />
        </PopoverContent>
      </Popover>
    </Box>
  )
}

/**
 * @typedef {{uBildungWissenschaftUndArbeit: string[];uEnergieGebaeude: string[];uKlimaschutzKlimawandelanpassung: string[];uMenschenImLand: string[];uNachhaltigkeitVorbildLand: string[];uRaumordnungMobilitaet: string[];teaserText: string;jahr: number;kategorie: string[];}} FixeElementeTop
 */

/**
 * @typedef {{fixeElementeTop: FixeElementeTop;}} PageBuilder
 */

/**
 * Represents a menu switch.
 * @param {object} props
 * @param {{[key: string]: {nodes: {id: string, title: string, uri: string, pagebuilder: PageBuilder, featuredImage: {node: {localFile: {childImageSharp: {gatsbyImageData: object}}}}}[]}}} props.data - The data object.
 * @param {string} props.type - The type of the menu.
 */
const MenuSwitch = ({ data, type }) => {
  const articles = useMemo(() => data[type.toLowerCase()].nodes, [data, type])

  switch (type) {
    case "Projekte":
      return (
        <DatenProjekteMenu
          articles={articles}
          type={type}
          disableYearSelect={false}
        />
      )
    case "Daten":
      return (
        <DatenProjekteMenu articles={articles} type={type} disableYearSelect />
      )
    case "Bereiche":
      return <BereicheMenu data={articles} />
    case "Blicke":
      return <BlickeMenu data={articles} />
    default:
      return null
  }
}
