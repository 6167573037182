import { extendTheme } from "@chakra-ui/react"

const theme = {
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  fonts: {
    heading: "CocoSharp-Regular",
    body: "CocoSharpL-Regular",
  },
  colors: {
    primary: "#005A9A",
    secondary: "#FFD533",
    light_blue: "#D5EBFA",
    dark_blue: "#01214A",
    turquoise: "#91DCD5",
    light_violet: "#DDCFEF",
    red: "#E73E0C",
    black: "#212121",
    white: "#FFFFFF",
  },
  components: {
    Text: {
      baseStyle: {
        whiteSpace: "pre-line",
        letterSpacing: "0.03px",
        color: "dark_blue",
      },
    },
    Heading: { baseStyle: { color: "dark_blue" } },
    // Popover: {
    //   variants: {
    //     responsive: {
    //       popper: {
    //         maxWidth: "unset",
    //         width: "unset",
    //         position: "unset !important",
    //       },
    //     },
    //   },
    // },
  },
  styles: {
    global: props => ({
      li: {
        listStylePosition: "inside",
      },
      "p, span, li": { color: "dark_blue" },
      "*, *::before, *::after": {
        borderColor: "transparent",
      },
    }),
  },
}

export default extendTheme(theme)
