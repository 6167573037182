import React from "react"
import { Link } from "gatsby"
import { SimpleGrid, Box, Heading, Text, VStack, Flex } from "@chakra-ui/react"
import { colors } from "../../../config/categories"
import CategorySVGSwitch from "../../../config/svgs"

const Auszug = ({ data }) => {
  return (
    <Box as="section" py={12}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3 }}
        overflow="hidden"
        marginRight="-2px"
        gridGap="1px"
      >
        {data.map(item => {
          const { title, uri } = item
          const { teaserText, kategorie } = item.pagebuilder.fixeElementeTop
          return (
            <Link to={uri} key={uri}>
              <Box
                outline="1px solid #002149"
                height="100%"
                pt={8}
                pb={4}
                px={{ md: 4 }}
                role="group"
                position="relative"
              >
                <Flex
                  direction="column"
                  height="100%"
                  alignItems="center"
                  pt="19px"
                >
                  {kategorie?.[0] && (
                    <Box
                      w="70px"
                      h="70px"
                      mb={4}
                      alignSelf="center"
                      mx="auto"
                      display="block"
                    >
                      {CategorySVGSwitch(kategorie[0])}
                    </Box>
                  )}
                  <Heading
                    as="h3"
                    fontFamily="CocoSharp-Bold"
                    fontSize="1.875rem"
                    lineHeight="1.875rem"
                    color="dark_blue"
                    mt={2}
                    mb={4}
                    textAlign="center"
                  >
                    {title}
                  </Heading>
                  <Box pb={4}>
                    <Text
                      overflow="hidden"
                      whiteSpace="normal"
                      color="dark_blue"
                      lineHeight="1.3125rem"
                      textAlign="center"
                      dangerouslySetInnerHTML={{
                        __html: teaserText,
                      }}
                    />
                  </Box>
                </Flex>
                {/* Hover Screen */}
                <Box
                  display="none"
                  _groupHover={{
                    display: "block",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: "100%",
                    backgroundColor: kategorie
                      ? `${colors[kategorie[0]]}90`
                      : null || "rgba(205, 239, 205, 0.9)",
                    backdropFilter: "blur(20px)",
                  }}
                >
                  <VStack align="stretch" height="100%">
                    <Flex
                      flex={3}
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                    >
                      {/* {kategorie && (
                        <Heading
                          mb={12}
                          as="h5"
                          textAlign="center"
                          fontFamily="CocoSharp-Bold"
                          fontSize="1.375rem"
                          lineHeight="1.4375rem"
                          px={4}
                        >
                          {kategorie.length > 1
                            ? kategorie.join(" & ")
                            : kategorie[0]}
                        </Heading>
                      )} */}
                      <Text
                        fontSize="xl"
                        textDecoration="underline"
                        textDecorationThickness="1px"
                        textDecorationColor="dark_blue"
                        mt={-3}
                        mb={4}
                        opacity={1}
                      >
                        Mehr erfahren
                      </Text>
                      <Arrow />
                    </Flex>
                  </VStack>
                </Box>
              </Box>
            </Link>
          )
        })}
      </SimpleGrid>
    </Box>
  )
}

export default Auszug

export const Arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="39.709"
    height="42.779"
    viewBox="0 0 39.709 42.779"
  >
    <g id="Group_1605" data-name="Group 1605" transform="translate(0 0.707)">
      <g id="Group_330" data-name="Group 330" transform="translate(0)">
        <line
          id="Line_42"
          data-name="Line 42"
          y1="39"
          transform="translate(39 20.682) rotate(90)"
          fill="none"
          stroke="#002149"
          strokeWidth="2"
        />
        <line
          id="Line_41"
          data-name="Line 41"
          y1="29.25"
          transform="translate(18.318) rotate(-45)"
          fill="none"
          stroke="#002149"
          strokeWidth="2"
        />
        <line
          id="Line_45"
          data-name="Line 45"
          y1="29.25"
          transform="translate(39.002 20.682) rotate(45)"
          fill="none"
          stroke="#002149"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
)
