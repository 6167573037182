import React from "react"

/**
 * @param {object} props
 * @param {"dark" | "light"} props.color
 * @param {number} [props.size]
 */
export const PlusIcon = ({ color = "light", size = 40 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 40 40"
  >
    <g id="Group_2224" data-name="Group 2224" transform="translate(-469 -484)">
      <circle
        id="Ellipse_85"
        data-name="Ellipse 85"
        cx="20"
        cy="20"
        r="20"
        transform="translate(469 484)"
        fill={color === "dark" ? "#01214A" : "#235a9a"}
      />
      <g id="Group_2206" data-name="Group 2206" transform="translate(479 494)">
        <line
          id="Line_186"
          data-name="Line 186"
          x1="20"
          transform="translate(0 10)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
      </g>
      <g
        id="Group_2222"
        data-name="Group 2222"
        transform="translate(479 513.5) rotate(-90)"
      >
        <line
          id="Line_186-2"
          data-name="Line 186"
          x1="20"
          transform="translate(0 10)"
          fill="none"
          stroke="#fff"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
)
