import React, { useState, useEffect, useMemo } from "react"
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Flex,
  Text,
  IconButton,
  Link,
  PopoverAnchor,
} from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import { NAV_ITEMS } from "../../Navbar"
import {
  MobileBereicheMenu,
  MobileBlickeMenu,
  MobileDatenProjekteMenu,
} from "./submenus"
import { MobileActiveIndicatorIcon, BlickeSvg } from "../../svgs"
import { MobileLogo } from "../../components/MobileLogo"

/**
 * Represents the desktop navigation.
 * @param {object} props
 * @param {object} props.data - The data object.
 * @param {string} props.pageType - The type of the page.
 */
const MobileNav = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)
  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  const [tabIndex, setTabIndex] = useState(0)

  const handleTabsChange = index => {
    setTabIndex(index)
  }

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("noscroll")
    } else {
      document.body.classList.remove("noscroll")
    }
  }, [isOpen])

  return (
    <>
      <Popover
        trigger="click"
        variant="responsive"
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={close}
        closeOnBlur={true}
      >
        <Portal>
          <PopoverAnchor>
            <Box
              position="absolute"
              display={{ md: "none" }}
              top={"-5px"}
              left={0}
              right={0}
              zIndex={1000}
            />
          </PopoverAnchor>
        </Portal>
        <PopoverTrigger>
          <Flex align="center" mr={4}>
            <IconButton
              onClick={open}
              icon={<OpenMenuIcon />}
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>
        </PopoverTrigger>

        <PopoverContent
          display={{ base: "flex", md: "none" }}
          position="absolute"
          overflowY="hidden"
          w="100vw"
          h="100dvh"
          left="50%"
          bg="primary"
          transform="translateX(-50%)!important"
          minHeight={"100px"}
          borderRadius={0}
          border={0}
          _focus={{ outline: "none" }}
        >
          <Box display={{ base: "block", md: "none" }}>
            <Flex
              display={{ base: "flex", md: "none" }}
              justify="space-between"
              align="center"
              minH={"90px"}
              bg="dark_blue"
              pr={4}
            >
              <Link
                as={GatsbyLink}
                to="/"
                ml={2}
                pr={{ base: 6, sm: null }}
                my={2}
                aria-label="Zur Homepage"
              >
                <Box sx={{ "> *": { maxWidth: "100%" } }} ml={2} mr={3}>
                  <MobileLogo />
                </Box>
              </Link>

              <IconButton
                onClick={close}
                icon={<CloseMenuIcon />}
                variant={"link"}
                aria-label={"Toggle Navigation"}
              />
            </Flex>

            <Tabs
              align="center"
              index={tabIndex}
              onChange={handleTabsChange}
              border={0}
            >
              <TabList
                pos="relative"
                overflowY="hidden"
                backgroundColor="dark_blue"
                border={0}
                px={4}
                pb={8}
                justifyContent="flex-start"
              >
                {NAV_ITEMS.map(navItem => {
                  const isActiveLink = NAV_ITEMS[tabIndex] === navItem
                  return (
                    <Tab
                      key={navItem}
                      _selected={{
                        fontWeight: "bold",
                        fontFamily: "CocoSharpL-Bold",
                      }}
                      _focus={{ outline: "none" }}
                      position="relative"
                      px={4}
                    >
                      {navItem === "Blicke" ? (
                        <Box pb="6px">
                          <BlickeSvg color="#fff" />
                        </Box>
                      ) : (
                        <Text as="span" color="white">
                          {navItem}
                        </Text>
                      )}
                      <Box
                        display={!isActiveLink && "none"}
                        pos="absolute"
                        bottom="-2.1rem"
                        left="50%"
                        w="100%"
                        transform="translateX(-50%)"
                        zIndex={2}
                        sx={{
                          svg: {
                            width: "100%",
                          },
                        }}
                      >
                        <MobileActiveIndicatorIcon />
                      </Box>
                    </Tab>
                  )
                })}
              </TabList>

              <TabPanels>
                {NAV_ITEMS.map((navItem, index) => {
                  return (
                    <TabPanel
                      key={NAV_ITEMS[index].toLowerCase()}
                      p={0}
                      overflowY="scroll"
                      position="relative"
                      h="100%"
                    >
                      <PanelSwitch
                        data={data}
                        navItem={navItem.toLowerCase()}
                        tabIndex={index}
                      />
                    </TabPanel>
                  )
                })}
              </TabPanels>
            </Tabs>
          </Box>
        </PopoverContent>
      </Popover>
    </>
  )
}

/**
 * @typedef {{uBildungWissenschaftUndArbeit: string[];uEnergieGebaeude: string[];uKlimaschutzKlimawandelanpassung: string[];uMenschenImLand: string[];uNachhaltigkeitVorbildLand: string[];uRaumordnungMobilitaet: string[];teaserText: string;jahr: number;kategorie: string[];}} FixeElementeTop
 */

/**
 * @typedef {{fixeElementeTop: FixeElementeTop;}} PageBuilder
 */

/**
 * Represents a menu switch.
 * @param {object} props
 * @param {{[key: string]: {nodes: {id: string, title: string, uri: string, pagebuilder: PageBuilder, featuredImage: {node: {localFile: {childImageSharp: {gatsbyImageData: object}}}}}[]}}} props.data - The data object.
 * @param {string} props.type - The type of the menu.
 */
const PanelSwitch = ({ data, navItem, tabIndex }) => {
  const articles = useMemo(() => data[navItem].nodes, [data, navItem])

  switch (NAV_ITEMS[tabIndex]) {
    case "Bereiche":
      return <MobileBereicheMenu data={articles} />
    case "Blicke":
      return <MobileBlickeMenu data={articles} />
    case "Projekte":
      return (
        <MobileDatenProjekteMenu
          articles={articles}
          type={NAV_ITEMS[tabIndex]}
        />
      )
    case "Daten":
      return (
        <MobileDatenProjekteMenu
          articles={articles}
          type={NAV_ITEMS[tabIndex]}
          disableYearSelect
        />
      )
    default:
      return null
  }
}

export default MobileNav

const CloseMenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.931"
    height="27.931"
    viewBox="0 0 27.931 27.931"
  >
    <g
      id="Group_1591"
      data-name="Group 1591"
      transform="translate(-1060.858 -41.711)"
    >
      <line
        id="Line_1"
        data-name="Line 1"
        x1="37"
        transform="translate(1088.081 42.419) rotate(135)"
        fill="none"
        stroke="#fff"
        strokeWidth="2"
      />
      <line
        id="Line_3"
        data-name="Line 3"
        x1="37"
        transform="translate(1061.565 42.772) rotate(45)"
        fill="none"
        stroke="#fff"
        strokeWidth="2"
      />
    </g>
  </svg>
)

const OpenMenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="29"
    viewBox="0 0 37 27"
  >
    <g
      id="Group_1591"
      data-name="Group 1591"
      transform="translate(-1056.5 -42.5)"
    >
      <line
        id="Line_1"
        data-name="Line 1"
        x1="37"
        transform="translate(1056.5 43.5)"
        fill="none"
        stroke="#315895"
        strokeWidth="2"
      />
      <line
        id="Line_2"
        data-name="Line 2"
        x1="37"
        transform="translate(1056.5 56.5)"
        fill="none"
        stroke="#315895"
        strokeWidth="2"
      />
      <line
        id="Line_3"
        data-name="Line 3"
        x1="37"
        transform="translate(1056.5 68.5)"
        fill="none"
        stroke="#315895"
        strokeWidth="2"
      />
    </g>
  </svg>
)
