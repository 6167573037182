// @ts-check

import React, { useState } from "react"
import { Link as GatsbyLink } from "gatsby"
import { VStack, Text, Link, HStack, Box } from "@chakra-ui/react"
import CategorySVGSwitch from "../../../../../config/svgs"
import { order } from "../../../../../config/categories"
import { Header } from "./components"

const MobileBereicheMenu = ({ data }) => {
  // Create array with unique years of all articles
  const years = [
    ...new Set(data.map(item => item.pagebuilder.fixeElementeTop.jahr)),
  ]

  const sortedYears = years.length > 1 ? years.sort((a, b) => b - a) : years

  const [currentYear, setCurrentYear] = useState(years[0])

  // Filter data by currently selected year and sort by category order defined in user config
  const filteredData = data
    .filter(a => a.pagebuilder.fixeElementeTop.jahr === currentYear)
    .filter(a => a.pagebuilder.fixeElementeTop.kategorie !== null)
    .sort(
      (a, b) =>
        order[a.pagebuilder.fixeElementeTop.kategorie[0]] -
        order[b.pagebuilder.fixeElementeTop.kategorie[0]]
    )

  return (
    <>
      <Header
        years={sortedYears}
        currentYear={currentYear}
        setCurrentYear={setCurrentYear}
      />
      <VStack
        align="flex-start"
        w="100%"
        spacing={0}
        pb={12}
        pt={2}
        overflowY="scroll"
        h="calc(100vh - 12rem)"
        px={4}
      >
        {filteredData.map((item, index) => {
          const { kategorie } = item.pagebuilder.fixeElementeTop

          return (
            <Link
              key={index}
              as={GatsbyLink}
              to={item.uri}
              w="100%"
              borderBottom="1px solid white"
              textDecorationColor={"white !important"}
              textUnderlineOffset="3px"
            >
              <BereicheMenuItem kategorie={kategorie} />
            </Link>
          )
        })}
      </VStack>
    </>
  )
}

const BereicheMenuItem = ({ kategorie }) => {
  return (
    <HStack justifyContent="flex-start" alignItems="center" wrap="wrap" py={4}>
      <Box w="50px" h="50px" flex="0 0 auto" flexShrink={0} mr={2}>
        {kategorie[0] && CategorySVGSwitch(kategorie[0])}
      </Box>
      <Box flex={2} alignItems="center">
        <Text
          color="white"
          textAlign="left"
          wordBreak="break-word"
          fontFamily={"CocoSharp-Regular"}
          fontSize={"1.375rem"}
          fontWeight={"normal"}
          lineHeight={"1.6rem"}
        >
          {kategorie[0]}
        </Text>
      </Box>
    </HStack>
  )
}

export default MobileBereicheMenu
