import * as React from "react"
import Layout from "./src/layout"
import { ChakraProvider } from "@chakra-ui/react"
import theme from "./src/theme"

import "./src/css/global.css"

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = ({ element }) => {
  return (
    <ChakraProvider theme={theme} resetCSS={true} portalZIndex={1000}>
      {element}
    </ChakraProvider>
  )
}
