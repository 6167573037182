// @ts-check

import React, { Fragment, useMemo, useState } from "react"
import { Link as GatsbyLink } from "gatsby"
import {
  VStack,
  Text,
  Link,
  useDisclosure,
  Stack,
  Flex,
  Collapse,
  HStack,
  Box,
  List,
  ListItem,
  ListIcon,
  IconButton,
  AccordionButton,
  AccordionItem,
  Accordion,
  AccordionPanel,
} from "@chakra-ui/react"
import { ArrowForwardIcon } from "@chakra-ui/icons"
import CategorySVGSwitch from "../../../../../config/svgs"
import { order } from "../../../../../config/categories"
import { Header } from "./components"
import { PlusIcon } from "../../../components/PlusIcon"
import { formatFunnelUri } from "../../../../utils/format-funnel-uri"
import { MinusIcon } from "../../../components/MinusIcon"

/**
 * @typedef {{uBildungWissenschaftUndArbeit: string[];uEnergieGebaeude: string[];uKlimaschutzKlimawandelanpassung: string[];uMenschenImLand: string[];uNachhaltigkeitVorbildLand: string[];uRaumordnungMobilitaet: string[];teaserText: string;jahr: number;kategorie: string[];}} FixeElementeTop
 */

/**
 * @typedef {{fixeElementeTop: FixeElementeTop;}} PageBuilder
 */

/**
 * @typedef {{id: string;title: string;uri: string;pagebuilder: PageBuilder;}} Page
 */

/**
 * @param {object} props
 * @param {Page[]} props.articles - The articles object.
 * @param {string} props.type - The type of the menu.
 * @param {boolean} props.disableYearSelect - Whether to disable the year select.
 */
const MobileDatenProjekteMenu = ({
  articles,
  type,
  disableYearSelect = false,
}) => {
  const years = [
    ...new Set(articles.map(item => item.pagebuilder.fixeElementeTop.jahr)),
  ]
  const sortedYears = years.length > 1 ? years.sort((a, b) => b - a) : years

  const [currentYear, setCurrentYear] = useState(
    sortedYears[sortedYears.length - 1]
  )

  const filteredData = disableYearSelect
    ? articles
    : articles.filter(a => a.pagebuilder.fixeElementeTop.jahr === currentYear)

  const categorizedData = useMemo(() => {
    return filteredData.reduce((acc, item) => {
      item.pagebuilder.fixeElementeTop.kategorie.forEach(cat =>
        acc[cat] ? acc[cat].push(item) : (acc[cat] = [item])
      )

      return acc
    }, {})
  }, [filteredData])

  return (
    <>
      {!disableYearSelect && (
        <Header
          years={sortedYears}
          currentYear={currentYear}
          setCurrentYear={setCurrentYear}
        />
      )}
      <VStack
        w="100%"
        spacing={0}
        align="normal"
        pt={4}
        pb={12}
        overflowY="scroll"
        h="calc(100vh - 12rem)"
        px={4}
      >
        {Object.entries(categorizedData)
          .sort((a, b) => order[a[0]] - order[b[0]]) // Sort array by category order defined in config
          .map(([key, value]) => {
            return (
              <MobileNavItem
                key={key}
                title={key}
                value={value}
                jahr={currentYear}
                type={type}
                disableYearSelect={disableYearSelect}
              />
            )
          })}
      </VStack>
    </>
  )
}

export default MobileDatenProjekteMenu

/**
 * @param {object} props
 * @param {string} props.title - The title of the menu item.
 * @param {Page[]} props.value - The value of the menu item.
 * @param {number} props.jahr - The year of the menu item.
 * @param {string} props.type - The type of the menu item.
 * @param {boolean} props.disableYearSelect - Whether to disable the year select.
 */
const MobileNavItem = ({ title, value, jahr, type, disableYearSelect }) => {
  const { isOpen, onToggle } = useDisclosure()

  const filtered = disableYearSelect
    ? value
    : value.filter(item => jahr === item.pagebuilder.fixeElementeTop.jahr)

  const { categorized, withoutSubcategories } = useMemo(() => {
    const activeKategorie = title

    const f = filtered.map(item => {
      let subKategorien = []

      if (
        activeKategorie === "Klimaschutz & Klimawandelanpassung" &&
        item.pagebuilder.fixeElementeTop.kategorie.includes(
          "Klimaschutz & Klimawandelanpassung"
        )
      ) {
        subKategorien =
          item.pagebuilder.fixeElementeTop.uKlimaschutzKlimawandelanpassung ??
          []
      }

      if (
        activeKategorie === "Energie" &&
        item.pagebuilder.fixeElementeTop.kategorie.includes("Energie")
      ) {
        subKategorien = item.pagebuilder.fixeElementeTop.uEnergieGebaeude ?? []
      }

      if (
        activeKategorie === "Raumordnung & Mobilität" &&
        item.pagebuilder.fixeElementeTop.kategorie.includes(
          "Raumordnung & Mobilität"
        )
      ) {
        subKategorien =
          item.pagebuilder.fixeElementeTop.uRaumordnungMobilitaet ?? []
      }

      if (
        activeKategorie === "Menschen im Land" &&
        item.pagebuilder.fixeElementeTop.kategorie.includes("Menschen im Land")
      ) {
        subKategorien = item.pagebuilder.fixeElementeTop.uMenschenImLand ?? []
      }

      if (
        activeKategorie === "Bildung, Wissenschaft & Arbeit" &&
        item.pagebuilder.fixeElementeTop.kategorie.includes(
          "Bildung, Wissenschaft & Arbeit"
        )
      ) {
        subKategorien =
          item.pagebuilder.fixeElementeTop.uBildungWissenschaftUndArbeit ?? []
      }

      if (
        activeKategorie === "Nachhaltigkeit, Vorbild Land" &&
        item.pagebuilder.fixeElementeTop.kategorie.includes(
          "Nachhaltigkeit, Vorbild Land"
        )
      ) {
        subKategorien =
          item.pagebuilder.fixeElementeTop.uNachhaltigkeitVorbildLand ?? []
      }

      return {
        ...item,
        subKategorien,
      }
    })

    const withSubcategories = f.filter(
      item => (item.subKategorien ?? []).length > 0
    )
    const withoutSubcategories = f.filter(
      item => (item.subKategorien ?? []).length < 1
    )

    const categorizedData = withSubcategories.reduce((acc, item) => {
      const subcategories = item.subKategorien ?? []

      subcategories.forEach(cat => {
        const catKey = cat || "-"

        acc[catKey] ? acc[catKey].push(item) : (acc[catKey] = [item])
      })

      return acc
    }, {})

    return {
      categorized: Object.entries(categorizedData),
      withoutSubcategories,
    }
  }, [filtered, title])

  const overViewUri = formatFunnelUri(
    type,
    disableYearSelect ? undefined : jahr,
    title
  )

  return (
    <>
      <Stack spacing={0} borderBottom="1px solid white">
        <Flex
          py={4}
          justify="space-between"
          align={"center"}
          _hover={{
            textDecoration: "none",
          }}
          width="100%"
          flex={1}
        >
          <HStack>
            <Box w="35px" h="35px" flex="0 0 auto" flexShrink={0} mr={2}>
              {CategorySVGSwitch(title)}
            </Box>
            <Text
              color="white"
              textAlign="left"
              as={GatsbyLink}
              to={overViewUri}
              fontFamily="CocoSharp-Regular"
              fontSize="1.375rem"
              lineHeight="1.6rem"
              _hover={{
                textDecoration: "underline",
                textUnderlineOffset: "2px",
              }}
            >
              {title}
            </Text>
          </HStack>
          <Box ml={2}>
            <IconButton
              onClick={onToggle}
              aria-label="Toggle collapse"
              variant={"link"}
            >
              {isOpen ? <MinusIcon color="dark" /> : <PlusIcon color="dark" />}
            </IconButton>
          </Box>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <Box px={4} mb={4}>
            <Accordion allowToggle allowMultiple>
              <List spacing={2} textAlign="left" pl={4}>
                {!!categorized.length &&
                  categorized.map(([subkategorie, items]) => {
                    return (
                      <AccordionItem
                        key={subkategorie}
                        sx={{
                          border: "none",
                        }}
                      >
                        {({ isExpanded: isChildExpanded }) => {
                          return (
                            <Fragment key={subkategorie}>
                              <AccordionButton>
                                <ListItem
                                  color="white"
                                  fontSize="sm"
                                  lineHeight="1rem"
                                  _focus={{
                                    outline:
                                      "-webkit-focus-ring-color auto 1px",
                                  }}
                                  display="flex"
                                  alignItems="center"
                                  gap={7}
                                  ml={"-2.5rem"}
                                >
                                  <Box display="inline">
                                    {isChildExpanded ? (
                                      <MinusIcon color="dark" size={20} />
                                    ) : (
                                      <PlusIcon color="dark" size={20} />
                                    )}
                                  </Box>
                                  <Text
                                    color="white"
                                    fontFamily="CocoSharpL-Bold"
                                    sx={
                                      isChildExpanded
                                        ? {
                                            textDecoration: "underline",
                                            textUnderlineOffset: "2px",
                                          }
                                        : {}
                                    }
                                  >
                                    {subkategorie}
                                  </Text>
                                </ListItem>
                              </AccordionButton>
                              <AccordionPanel p={0} pb={4} overflowX="visible">
                                <List spacing={2}>
                                  {items.map(item => (
                                    <ListItem
                                      pl={6}
                                      color="white"
                                      key={item.uri}
                                      fontSize="sm"
                                      lineHeight="1rem"
                                      _focus={{
                                        outline:
                                          "-webkit-focus-ring-color auto 1px",
                                      }}
                                    >
                                      <ListIcon
                                        as={ArrowForwardIcon}
                                        color="white"
                                        ml={"-1.4rem"}
                                      />
                                      <Link as={GatsbyLink} to={item.uri}>
                                        {item.title}
                                      </Link>
                                    </ListItem>
                                  ))}
                                </List>
                              </AccordionPanel>
                            </Fragment>
                          )
                        }}
                      </AccordionItem>
                    )
                  })}
                {!!withoutSubcategories.length &&
                  withoutSubcategories.map(item => (
                    <ListItem
                      color="white"
                      key={item.uri}
                      fontSize="sm"
                      lineHeight="1rem"
                      _focus={{
                        outline: "-webkit-focus-ring-color auto 1px",
                      }}
                    >
                      <ListIcon
                        as={ArrowForwardIcon}
                        color="white"
                        ml={"-1.4rem"}
                      />
                      <Link as={GatsbyLink} to={item.uri}>
                        {item.title}
                      </Link>
                    </ListItem>
                  ))}
              </List>
            </Accordion>
          </Box>
        </Collapse>
      </Stack>
    </>
  )
}
