import React, { useState, useEffect } from "react"

import { useFlexSearch } from "react-use-flexsearch"

import { Link as GatsbyLink } from "gatsby"

import {
  Box,
  Flex,
  Text,
  Center,
  Button,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Input,
  VStack,
} from "@chakra-ui/react"

import { SearchIcon } from "../../svgs"
import CategorySVGSwitch from "../../../../config/svgs"

const SearchInputDesktop = ({ searchValues }) => {
  const initialFocusRef = React.useRef()

  const [popoverIsOpen, setPopoverIsOpen] = React.useState(false)
  const open = () => setPopoverIsOpen(!popoverIsOpen)
  const close = () => {
    setQuery("")
    setPopoverIsOpen(false)
  }

  const [query, setQuery] = useState("")
  const results = useFlexSearch(query, searchValues.index, searchValues.store)

  const handleChange = event => setQuery(event.target.value)

  useEffect(() => {
    setQuery("")
  }, [])

  return (
    <Popover
      placement="left-start"
      initialFocusRef={initialFocusRef}
      isOpen={popoverIsOpen}
      onClose={close}
    >
      <PopoverTrigger>
        <Button
          display={{ base: "none", md: "flex" }}
          mt={{ base: 0, md: -2 }}
          px={4}
          href={"#"}
          position="relative"
          fontSize="sm"
          onClick={open}
          _hover={{
            opacity: "0.9",
          }}
          bg="none"
        >
          <Text pt="4px" letterSpacing="1px" fontFamily="CocoSharpL-Bold">
            SUCHEN
          </Text>
          <SearchIcon />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        w="690px"
        maxW="55vw"
        mr={-2}
        borderRadius={0}
        borderWidth="0px"
      >
        <Input
          placeholder={"SUCHBEGRIFF EINGEBEN"}
          _placeholder={{
            color: "dark_blue",
            textAlign: "center",
          }}
          pt={1}
          size="sm"
          height="40px"
          borderColor="dark_blue"
          color="dark_blue"
          letterSpacing="1px"
          focusBorderColor="dark_blue"
          borderRadius={0}
          _focus={{ borderWidth: "1px", boxShadow: "none" }}
          _hover={{ borderColor: "dark_blue" }}
          ref={initialFocusRef}
          onChange={handleChange}
          value={query}
        />
        {results.length > 0 && (
          <Box
            p={2}
            maxHeight="60vh"
            overflowX="scroll"
            borderColor="dark_blue"
            borderBottom="1px"
            borderRight="1px"
            borderLeft="1px"
          >
            {results.map((node, index) => {
              const t = node.type || ""
              const type = t.toLowerCase() === "blicke" ? "blicke" : t
              return (
                <Link
                  as={GatsbyLink}
                  to={node.uri}
                  onClick={close}
                  key={node.id}
                  _focus={{
                    outline: "-webkit-focus-ring-color auto 1px",
                  }}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    dir="row"
                    borderBottomWidth={!(index === results.length - 1) && "1px"}
                    borderBottomColor="dark_blue"
                  >
                    {type === "blicke" ? (
                      <Center h="50px" w="40px">
                        <BlickeIcon />
                      </Center>
                    ) : (
                      <Center
                        width="100%"
                        maxW="40px"
                        maxH="55px"
                        flexShrink={0}
                        pl={{ base: 0, lg: "3px" }}
                      >
                        {node.kategorie &&
                          node.kategorie[0] &&
                          CategorySVGSwitch(node.kategorie[0])}
                      </Center>
                    )}
                    <Box px={2} flex={1} minW={"0px"} flexShrink={1} pt="4px">
                      <Text
                        as="h5"
                        fontSize="1.0625rem"
                        lineHeight="1.3125rem"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                      >
                        {node.title}
                      </Text>
                      <Text
                        fontSize="0.875rem"
                        lineHeight="1.3125rem"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        sx={{
                          "*": {
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          },
                        }}
                        dangerouslySetInnerHTML={{
                          __html: node.excerpt ? node.excerpt : null,
                        }}
                      />
                    </Box>
                    <VStack
                      w="100%"
                      maxW="60px"
                      flexShrink={0}
                      h="55px"
                      borderBottom="1px solid white"
                      align="stretch"
                      spacing={0}
                    >
                      <Center w="100%" flex={1} bgColor="dark_blue">
                        <Text
                          color="white"
                          as="span"
                          fontFamily="CocoSharpL-Bold"
                          fontSize="0.75rem"
                          textDecor="none"
                          pt="4px"
                        >
                          {type}
                        </Text>
                      </Center>
                      <Center w="100%" flex={1} bgColor="primary">
                        <Text
                          color="white"
                          as="span"
                          fontFamily="CocoSharpL-Bold"
                          fontSize="1rem"
                          textDecor="none"
                          pt="4px"
                        >
                          {node.jahr}
                        </Text>
                      </Center>
                    </VStack>
                  </Flex>
                </Link>
              )
            })}
          </Box>
        )}
      </PopoverContent>
    </Popover>
  )
}

export default SearchInputDesktop

const BlickeIcon = () => (
  <svg
    id="Group_2286"
    data-name="Group 2286"
    xmlns="http://www.w3.org/2000/svg"
    width="39.314"
    height="31.089"
    viewBox="0 0 23.314 19.089"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_150"
          data-name="Rectangle 150"
          width="23.314"
          height="19.089"
          fill="#0e214a"
          stroke="#0e214a"
          strokeWidth="0.3"
        />
      </clipPath>
    </defs>
    <g id="Group_2285" data-name="Group 2285" clipPath="url(#clip-path)">
      <path
        id="Path_2520"
        data-name="Path 2520"
        d="M387.849,153.529V142.3a.376.376,0,1,0-.752,0v11.228a.376.376,0,0,0,.752,0"
        transform="translate(-375.816 -137.789)"
        fill="#0e214a"
        stroke="#0e214a"
        strokeWidth="0.3"
      />
      <path
        id="Path_2521"
        data-name="Path 2521"
        d="M22.938,1.686A9.757,9.757,0,0,1,20.1.98,13.615,13.615,0,0,0,15.708,0a4.492,4.492,0,0,0-4.051,2.174,4.569,4.569,0,0,0-.536-.718A4.583,4.583,0,0,0,7.606,0a13.615,13.615,0,0,0-4.4.981,9.758,9.758,0,0,1-2.834.706A.376.376,0,0,0,0,2.062V17.208a.376.376,0,0,0,.376.376,10.2,10.2,0,0,0,3.08-.748,13.045,13.045,0,0,1,4.149-.94,3.829,3.829,0,0,1,2.96,1.211.376.376,0,0,0,.555-.507,4.583,4.583,0,0,0-3.515-1.456,13.615,13.615,0,0,0-4.4.981,12.549,12.549,0,0,1-2.458.684V2.42a12.435,12.435,0,0,0,2.7-.729A13.044,13.044,0,0,1,7.606.751a3.71,3.71,0,0,1,3.692,2.4.376.376,0,0,0,.719,0,3.746,3.746,0,0,1,3.692-2.4,13.044,13.044,0,0,1,4.149.94,12.432,12.432,0,0,0,2.7.729V16.81a12.553,12.553,0,0,1-2.458-.684,13.616,13.616,0,0,0-4.4-.981A4.583,4.583,0,0,0,12.193,16.6a.376.376,0,1,0,.555.507,3.829,3.829,0,0,1,2.96-1.211,13.044,13.044,0,0,1,4.149.94,10.2,10.2,0,0,0,3.08.748.376.376,0,0,0,.376-.376V2.062a.376.376,0,0,0-.376-.376"
        transform="translate(0 0.001)"
        fill="#0e214a"
        stroke="#0e214a"
        strokeWidth="0.3"
      />
      <path
        id="Path_2522"
        data-name="Path 2522"
        d="M22.938,573.027a9.757,9.757,0,0,1-2.834-.706,13.616,13.616,0,0,0-4.4-.981,4.607,4.607,0,0,0-3.4,1.333H11a4.607,4.607,0,0,0-3.4-1.333,13.615,13.615,0,0,0-4.4.981,9.757,9.757,0,0,1-2.834.706.376.376,0,1,0,0,.752,10.2,10.2,0,0,0,3.08-.748,13.044,13.044,0,0,1,4.149-.94,3.829,3.829,0,0,1,2.96,1.211.376.376,0,0,0,.278.122H12.47a.377.377,0,0,0,.278-.122,3.829,3.829,0,0,1,2.96-1.211,13.044,13.044,0,0,1,4.149.94,10.2,10.2,0,0,0,3.08.748.376.376,0,0,0,0-.752"
        transform="translate(0 -554.69)"
        fill="#0e214a"
        stroke="#0e214a"
        strokeWidth="0.3"
      />
    </g>
  </svg>
)
