import React from "react"

export const MobileLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="52.784"
    height="49.638"
    viewBox="0 0 52.784 49.638"
  >
    <g id="Group_2170" data-name="Group 2170" transform="translate(0 0)">
      <path
        id="Pfad_1458"
        data-name="Pfad 1458"
        d="M7380.547,2406l-7.93,49.638h-44.854l7.93-49.638Z"
        transform="translate(-7327.763 -2405.995)"
        fill="#005a9a"
      />
      <path
        id="Pfad_1459"
        data-name="Pfad 1459"
        d="M7519.934,2428.71h-5.109l9.814,7.807-.946-4.911,4.551-2.9h-5.109l-.947-4.91Z"
        transform="translate(-7479.514 -2420.622)"
        fill="#ffd500"
      />
      <path
        id="Pfad_1460"
        data-name="Pfad 1460"
        d="M7396.486,2466.4l-9.64-15.093h-8.16l-5.193,33.365h11.125l2.226-14.3v-.795l9.643,15.091h8.16l3.209-20.612-9.814-8.435-1.555,9.983Z"
        transform="translate(-7365.332 -2443.224)"
        fill="#ffd500"
      />
    </g>
  </svg>
)
