import React from "react"

export const UmweltSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="301.819"
    height="106.28"
    viewBox="0 0 301.819 106.28"
    style={{ display: "inline-block", width: "100%", height: "100%" }}
  >
    <g id="Group_1542" data-name="Group 1542" transform="translate(0 -709.37)">
      <path
        id="Path_1429"
        data-name="Path 1429"
        d="M226.168,782.889H123.483l-.709,4.257H296.716l.7-4.257Z"
        fill="#005a9a"
      />
      <g id="Group_1536" data-name="Group 1536">
        <path
          id="Path_1430"
          data-name="Path 1430"
          d="M129.525,745.964l1.566-7.951h2.719l-1.559,7.942c-.311,1.589.448,2.693,1.754,2.693,1.249,0,2.213-.976,2.547-2.693l1.559-7.942h2.718l-1.559,7.951c-.669,3.4-2.931,5.329-5.689,5.329-2.947,0-4.652-2.29-4.056-5.329"
          fill="#005a9a"
        />
      </g>
      <path
        id="Path_1431"
        data-name="Path 1431"
        d="M155.4,738.011l-2.556,13h-2.726l1.663-7.33-4.568,7.1h-.43l-2.413-7.184-1.2,7.41h-2.726l2.557-13H145.7l1.915,7.559,4.754-7.56Z"
        fill="#005a9a"
      />
      <path
        id="Path_1432"
        data-name="Path 1432"
        d="M156.71,738.013h2.661l.6,6.555,3.794-6.507h.385l1.225,6.5,3.134-6.546h3.035l-6.335,13.158h-1l-1.318-7.063-4.04,7.063h-.921Z"
        fill="#005a9a"
      />
      <path
        id="Path_1433"
        data-name="Path 1433"
        d="M175.068,740.585l-.5,2.54h4.161l-.513,2.6h-4.163l-.522,2.684h4.9l-.513,2.6H170.3l2.555-13h7.614l-.506,2.572Z"
        fill="#005a9a"
      />
      <path
        id="Path_1434"
        data-name="Path 1434"
        d="M188.1,748.414l-.515,2.6h-7.721l2.556-13h2.725l-2.04,10.4Z"
        fill="#005a9a"
      />
      <path
        id="Path_1435"
        data-name="Path 1435"
        d="M196.755,740.6h-3.012L191.7,751.01h-2.726l2.048-10.408H188l.506-2.588h8.757Z"
        fill="#005a9a"
      />
      <path
        id="Path_1436"
        data-name="Path 1436"
        d="M196.475,743.536h6.146l-.45,2.282h-6.144Z"
        fill="#005a9a"
      />
      <g id="Group_1537" data-name="Group 1537">
        <path
          id="Path_1437"
          data-name="Path 1437"
          d="M208.631,745.964l1.566-7.951h2.719l-1.559,7.942c-.311,1.589.448,2.693,1.754,2.693,1.249,0,2.212-.976,2.547-2.693l1.559-7.942h2.718l-1.56,7.951c-.668,3.4-2.93,5.329-5.688,5.329-2.947,0-4.652-2.29-4.056-5.329"
          fill="#005a9a"
        />
      </g>
      <path
        id="Path_1438"
        data-name="Path 1438"
        d="M232.262,737.981l-2.612,13.272h-.237l-5.737-7.475-1.419,7.247h-2.71l2.611-13.253h.246l5.721,7.449,1.427-7.24Z"
        fill="#005a9a"
      />
      <g id="Group_1538" data-name="Group 1538">
        <path
          id="Path_1439"
          data-name="Path 1439"
          d="M243.659,744.528c-.816,6.482-6.863,6.482-7.86,6.482h-3.81l2.554-12.989,3.812-.007c2.562-.009,5.933,1.539,5.3,6.514m-5.835-3.926h-1.07l-1.533,7.8h1.093a4.366,4.366,0,0,0,4.587-3.894c.375-2.6-1.241-3.91-3.077-3.91"
          fill="#005a9a"
        />
      </g>
      <path
        id="Path_1440"
        data-name="Path 1440"
        d="M129.95,760.091l-.5,2.54h4.164l-.514,2.605h-4.163l-.522,2.684h4.9l-.513,2.6h-7.615l2.555-13h7.614l-.506,2.572Z"
        fill="#005a9a"
      />
      <path
        id="Path_1441"
        data-name="Path 1441"
        d="M147.452,757.486l-2.612,13.272h-.235l-5.739-7.475-1.419,7.247h-2.71l2.611-13.253h.246l5.721,7.449,1.427-7.24Z"
        fill="#005a9a"
      />
      <path
        id="Path_1442"
        data-name="Path 1442"
        d="M151.945,760.091l-.5,2.54h4.163l-.514,2.605h-4.163l-.522,2.684h4.9l-.513,2.6h-7.615l2.555-13h7.614l-.506,2.572Z"
        fill="#005a9a"
      />
      <g id="Group_1539" data-name="Group 1539">
        <path
          id="Path_1443"
          data-name="Path 1443"
          d="M163.874,765.34l2.579,5.175H163.44l-2.782-6.078-1.192,6.078h-2.717l2.545-13h3.886a3.519,3.519,0,0,1,3.714,4.208,4.4,4.4,0,0,1-3.02,3.613m-1.151-5.233h-1.216l-.645,3.264,1.216.009a2.016,2.016,0,0,0,2.139-1.629,1.412,1.412,0,0,0-1.494-1.644"
          fill="#005a9a"
        />
        <path
          id="Path_1444"
          data-name="Path 1444"
          d="M180.5,763.219a10.108,10.108,0,0,1-.139,1.1c-.717,3.855-3.51,6.459-7.214,6.459-3.885,0-6.008-2.87-5.387-6.74a7.815,7.815,0,0,1,7.868-6.781,5.225,5.225,0,0,1,4,2.274l-1.877,2.168a3.339,3.339,0,0,0-2.8-1.8,4.916,4.916,0,0,0-4.473,4.136c-.457,2.467,1.028,4.111,3.037,4.111a4.13,4.13,0,0,0,3.566-2.515h-2.995l.473-2.411Z"
          fill="#005a9a"
        />
      </g>
      <path
        id="Path_1445"
        data-name="Path 1445"
        d="M183.412,757.519h2.717l-2.554,13h-2.717Z"
        fill="#005a9a"
      />
      <path
        id="Path_1446"
        data-name="Path 1446"
        d="M190.633,760.091l-.5,2.54H194.3l-.514,2.605h-4.163l-.522,2.684H194l-.513,2.6h-7.615l2.555-13h7.614l-.506,2.572Z"
        fill="#005a9a"
      />
      <path
        id="Path_1447"
        data-name="Path 1447"
        d="M213.158,757.519h2.719l-2.556,13H210.6Z"
        fill="#005a9a"
      />
      <g id="Group_1540" data-name="Group 1540">
        <path
          id="Path_1448"
          data-name="Path 1448"
          d="M222.747,765.34l2.579,5.175h-3.011l-2.784-6.078-1.191,6.078h-2.718l2.547-13h3.884a3.519,3.519,0,0,1,3.714,4.208,4.4,4.4,0,0,1-3.02,3.613m-1.151-5.233H220.38l-.645,3.264,1.216.009a2.016,2.016,0,0,0,2.139-1.629,1.411,1.411,0,0,0-1.494-1.644"
          fill="#005a9a"
        />
      </g>
      <path
        id="Path_1449"
        data-name="Path 1449"
        d="M236.282,760.107h-3.011l-2.049,10.408H228.5l2.048-10.408h-3.018l.505-2.588h8.757Z"
        fill="#005a9a"
      />
      <g id="Group_1541" data-name="Group 1541">
        <path
          id="Path_1450"
          data-name="Path 1450"
          d="M235.545,767.935l2.146-1.451a2.938,2.938,0,0,0,2.547,1.66,1.778,1.778,0,0,0,1.836-1.2c.188-1.072-1.223-1.839-2.146-2.4-1.274-.782-2.587-1.855-2.139-4.064a4.383,4.383,0,0,1,4.489-3.234,3.466,3.466,0,0,1,3.494,2.388l-2.139,1.444a1.743,1.743,0,0,0-1.722-1.267,1.518,1.518,0,0,0-1.517,1.217c-.148.92.872,1.331,1.925,1.959,2.065,1.234,2.766,2.468,2.441,4.128a4.672,4.672,0,0,1-4.89,3.661,4.37,4.37,0,0,1-4.325-2.838"
          fill="#005a9a"
        />
        <path
          id="Path_1451"
          data-name="Path 1451"
          d="M246.428,763.864a7.716,7.716,0,0,1,7.68-6.587,5.161,5.161,0,0,1,4.26,2.757l-2.008,1.733c-.9-1.322-1.738-1.853-2.832-1.853a4.774,4.774,0,0,0-4.366,3.959c-.49,2.409.874,4.272,2.89,4.272a5.228,5.228,0,0,0,3.28-1.483l1.159,2.274a7.4,7.4,0,0,1-4.808,1.829c-3.705,0-5.932-2.943-5.255-6.9"
          fill="#005a9a"
        />
      </g>
      <path
        id="Path_1452"
        data-name="Path 1452"
        d="M269.953,757.519l-2.554,13h-2.718l1.019-5.21h-4.169l-1.021,5.21h-2.717l2.554-13h2.718l-1.019,5.2h4.169l1.021-5.2Z"
        fill="#005a9a"
      />
      <path
        id="Path_1453"
        data-name="Path 1453"
        d="M277.728,768.7h-4.791l-1.07,1.815h-3.158L277.2,757.2h.434l3.255,13.32h-2.758Zm-.5-2.225-.808-3.66-2.162,3.66Z"
        fill="#005a9a"
      />
      <path
        id="Path_1454"
        data-name="Path 1454"
        d="M286.961,760.091l-.513,2.62h4.169l-.514,2.6H285.94l-1.028,5.216H282.2l2.571-13.044h7.614l-.514,2.6Z"
        fill="#005a9a"
      />
      <path
        id="Path_1455"
        data-name="Path 1455"
        d="M293.063,757.519l-.506,2.588h3.019l-2.049,10.41h2.726l2.049-10.41h3.012l.505-2.588Z"
        fill="#005a9a"
      />
      <path
        id="Path_1456"
        data-name="Path 1456"
        d="M197.012,757.519h2.661l.6,6.555,3.794-6.507h.385l1.225,6.5,3.134-6.546h3.035l-6.335,13.158h-1l-1.318-7.063-4.04,7.063h-.921Z"
        fill="#005a9a"
      />
      <path
        id="Path_1457"
        data-name="Path 1457"
        d="M0,709.37V815.65H110.356l17.925-106.28Z"
        fill="#ffc400"
      />
      <path
        id="Path_1458"
        data-name="Path 1458"
        d="M94.65,737.98l-8.267,49.166H39.618l8.268-49.166Z"
        fill="#005a9a"
      />
      <path
        id="Path_1459"
        data-name="Path 1459"
        d="M79.753,746.412H74.425l10.233,8.14-.987-5.12,4.745-3.02H83.088l-.987-5.119Z"
        fill="#ffc400"
      />
      <path
        id="Path_1460"
        data-name="Path 1460"
        d="M72.1,760.978,62.049,746.412H53.541l-5.414,32.2h11.6l2.32-13.8v-.767L72.1,778.609h8.508l3.345-19.892-10.232-8.14L72.1,760.211Z"
        fill="#ffc400"
      />
    </g>
  </svg>
)

export const BlickeReiterSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="97"
    height="30"
    viewBox="0 0 97 30"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_137"
          data-name="Rectangle 137"
          width="58.451"
          height="16.63"
          fill="#fff"
        />
      </clipPath>
    </defs>
    <g id="Group_1619" data-name="Group 1619" transform="translate(-530 -1256)">
      <path
        id="Path_1586"
        data-name="Path 1586"
        d="M0,0H86L76,30H-11Z"
        transform="translate(541 1256)"
        fill="#002149"
      />
      <g
        id="Group_2194"
        data-name="Group 2194"
        transform="translate(549.5 1262.504)"
      >
        <g id="Group_2189" data-name="Group 2189" transform="translate(0 0)">
          <g
            id="Group_2188"
            data-name="Group 2188"
            transform="translate(0 0)"
            clipPath="url(#clip-path)"
          >
            <path
              id="Path_2502"
              data-name="Path 2502"
              d="M8.191,11.473a2.63,2.63,0,0,1-.324,1.313,2.315,2.315,0,0,1-.883.894A2.483,2.483,0,0,1,5.732,14,2.544,2.544,0,0,1,4.47,13.68a2.358,2.358,0,0,1-.905-.9,2.737,2.737,0,0,1-.006-2.615,2.436,2.436,0,0,1,.894-.9,2.468,2.468,0,0,1,1.268-.33,2.489,2.489,0,0,1,1.251.324,2.333,2.333,0,0,1,.894.9,2.631,2.631,0,0,1,.324,1.313m3.352-.022a5.741,5.741,0,0,0-.726-2.844,5.584,5.584,0,0,0-1.95-2.062,4.977,4.977,0,0,0-2.676-.76,4,4,0,0,0-2.564.838c-.079.063-.155.13-.229.2V.679H0v16.18H2.682L3.006,15.7a4.908,4.908,0,0,0,.637.61,4.01,4.01,0,0,0,2.548.821,5.061,5.061,0,0,0,2.682-.749,5.412,5.412,0,0,0,1.95-2.062,5.858,5.858,0,0,0,.721-2.866"
              transform="translate(0 -0.544)"
              fill="#fff"
            />
            <path
              id="Path_2503"
              data-name="Path 2503"
              d="M83.4,16.192a5.286,5.286,0,0,0,1.717-1.051L83.4,12.621a3.32,3.32,0,0,1-.908.545,2.823,2.823,0,0,1-1.029.182,2.25,2.25,0,0,1-1.161-.314,2.329,2.329,0,0,1-.847-.853,2.362,2.362,0,0,1-.314-1.21,2.475,2.475,0,0,1,.314-1.238,2.255,2.255,0,0,1,.847-.864,2.3,2.3,0,0,1,1.172-.308,2.649,2.649,0,0,1,1.018.193,4.159,4.159,0,0,1,.919.534l1.716-2.52A5.88,5.88,0,0,0,83.4,5.716a5.681,5.681,0,0,0-2.124-.391,5.569,5.569,0,0,0-2.883.759,5.464,5.464,0,0,0-2.019,2.058,5.66,5.66,0,0,0-.732,2.828,5.575,5.575,0,0,0,.726,2.806,5.482,5.482,0,0,0,2.008,2.036,5.622,5.622,0,0,0,2.922.759,5.822,5.822,0,0,0,2.1-.38M73.989,5.6H70.446V16.252h3.543Zm-.583-1.816a2.064,2.064,0,0,0,.754-.754,1.934,1.934,0,0,0,0-1.97A2.15,2.15,0,0,0,73.406.3a2.061,2.061,0,0,0-1.1-.3A2.081,2.081,0,0,0,70.44,1.056a1.972,1.972,0,0,0-.27.99,1.941,1.941,0,0,0,.27.985,2.075,2.075,0,0,0,.748.754,2.114,2.114,0,0,0,1.106.286,2.143,2.143,0,0,0,1.111-.286M68.184.165H64.641V16.252h3.543Z"
              transform="translate(-51.743 0)"
              fill="#fff"
            />
          </g>
        </g>
        <path
          id="Path_2504"
          data-name="Path 2504"
          d="M198.334,32.426l4.078-4.357h-4.39l-3.7,4.353,4.462,6.3h4.258Z"
          transform="translate(-155.545 -22.468)"
          fill="#fff"
        />
        <g id="Group_2191" data-name="Group 2191" transform="translate(0 0)">
          <g
            id="Group_2190"
            data-name="Group 2190"
            transform="translate(0 0)"
            clipPath="url(#clip-path)"
          >
            <path
              id="Path_2505"
              data-name="Path 2505"
              d="M245.642,29.8a3.288,3.288,0,0,1,.122.417h-4.069a2.793,2.793,0,0,1,.159-.485,2.03,2.03,0,0,1,.761-.913,2.16,2.16,0,0,1,1.172-.3,1.835,1.835,0,0,1,1.854,1.285m3.422,1.409a6.484,6.484,0,0,0-.631-2.926,4.614,4.614,0,0,0-1.86-1.978,5.832,5.832,0,0,0-2.965-.71,5.229,5.229,0,0,0-2.818.778,5.443,5.443,0,0,0-1.956,2.1,6.052,6.052,0,0,0-.7,2.9,5.753,5.753,0,0,0,.739,2.869,5.587,5.587,0,0,0,2.057,2.1,5.716,5.716,0,0,0,2.976.784,6.307,6.307,0,0,0,2.227-.378,5.813,5.813,0,0,0,1.8-1.065l-2.511-1.821a6.708,6.708,0,0,1-1.221.2,2.747,2.747,0,0,1-1.342-.321,2.33,2.33,0,0,1-.93-.947,2.449,2.449,0,0,1-.141-.31h7.182c0-.007.064-.479.079-.671s.023-.389.023-.592"
              transform="translate(-190.613 -20.491)"
              fill="#fff"
            />
          </g>
        </g>
        <rect
          id="Rectangle_139"
          data-name="Rectangle 139"
          width="3.543"
          height="16.087"
          transform="translate(34.971 0.165)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
)

export const BlickeSvg = ({ color = "#01214a" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="92.963"
    height="18.089"
    viewBox="0 0 92.963 19.089"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_137"
          data-name="Rectangle 137"
          width="63.963"
          height="18.198"
          fill={color}
        />
      </clipPath>
      <clipPath id="clip-path-3">
        <rect
          id="Rectangle_150"
          data-name="Rectangle 150"
          width="23.314"
          height="19.089"
          fill={color}
          stroke={color}
          strokeWidth="0.3"
        />
      </clipPath>
    </defs>
    <g id="Group_2294" data-name="Group 2294" transform="translate(-20 -84.34)">
      <g
        id="Group_2281"
        data-name="Group 2281"
        transform="translate(49 85.232)"
      >
        <g id="Group_2189" data-name="Group 2189" transform="translate(0 0)">
          <g
            id="Group_2188"
            data-name="Group 2188"
            transform="translate(0 0)"
            clipPath="url(#clip-path)"
          >
            <path
              id="Path_2502"
              data-name="Path 2502"
              d="M8.963,12.491a2.878,2.878,0,0,1-.355,1.437,2.533,2.533,0,0,1-.966.978,2.717,2.717,0,0,1-1.37.348,2.784,2.784,0,0,1-1.382-.348,2.58,2.58,0,0,1-.99-.984,3,3,0,0,1-.006-2.861,2.666,2.666,0,0,1,.978-.984,2.7,2.7,0,0,1,1.388-.361,2.724,2.724,0,0,1,1.37.355,2.553,2.553,0,0,1,.978.984,2.879,2.879,0,0,1,.355,1.437m3.668-.024a6.282,6.282,0,0,0-.795-3.112A6.11,6.11,0,0,0,9.7,7.1a5.446,5.446,0,0,0-2.929-.831,4.374,4.374,0,0,0-2.806.917c-.087.069-.169.142-.251.216V.679H0V18.385H2.935l.355-1.272a5.371,5.371,0,0,0,.7.667,4.388,4.388,0,0,0,2.788.9,5.538,5.538,0,0,0,2.935-.819A5.922,5.922,0,0,0,11.843,15.6a6.411,6.411,0,0,0,.789-3.136"
              transform="translate(0 -0.531)"
              fill={color}
            />
            <path
              id="Path_2503"
              data-name="Path 2503"
              d="M85.172,17.719a5.784,5.784,0,0,0,1.879-1.15l-1.879-2.757a3.633,3.633,0,0,1-.993.6,3.089,3.089,0,0,1-1.126.2,2.463,2.463,0,0,1-1.27-.343,2.549,2.549,0,0,1-.927-.933,2.585,2.585,0,0,1-.343-1.325,2.708,2.708,0,0,1,.343-1.355,2.468,2.468,0,0,1,.927-.945,2.52,2.52,0,0,1,1.282-.337,2.9,2.9,0,0,1,1.114.211,4.551,4.551,0,0,1,1.006.584l1.878-2.758a6.434,6.434,0,0,0-1.891-1.15,6.216,6.216,0,0,0-2.324-.428,6.094,6.094,0,0,0-3.155.831,5.979,5.979,0,0,0-2.21,2.252,6.194,6.194,0,0,0-.8,3.095,6.1,6.1,0,0,0,.795,3.07,6,6,0,0,0,2.2,2.228,6.153,6.153,0,0,0,3.2.831,6.371,6.371,0,0,0,2.3-.415m-10.3-11.59H70.993V17.785h3.877Zm-.638-1.987a2.258,2.258,0,0,0,.825-.825,2.116,2.116,0,0,0,0-2.155,2.352,2.352,0,0,0-.825-.837A2.255,2.255,0,0,0,73.028,0a2.277,2.277,0,0,0-2.041,1.156,2.158,2.158,0,0,0-.295,1.084,2.125,2.125,0,0,0,.295,1.078,2.271,2.271,0,0,0,.819.825,2.313,2.313,0,0,0,1.21.313,2.345,2.345,0,0,0,1.216-.313M68.518.181H64.641v17.6h3.877Z"
              transform="translate(-50.526 0)"
              fill={color}
            />
          </g>
        </g>
        <path
          id="Path_2504"
          data-name="Path 2504"
          d="M198.713,32.837l4.463-4.768h-4.8l-4.052,4.764,4.883,6.892h4.66Z"
          transform="translate(-151.889 -21.94)"
          fill={color}
        />
        <g id="Group_2191" data-name="Group 2191" transform="translate(0 0)">
          <g
            id="Group_2190"
            data-name="Group 2190"
            transform="translate(0 0)"
            clipPath="url(#clip-path)"
          >
            <path
              id="Path_2505"
              data-name="Path 2505"
              d="M246.351,30.2a3.6,3.6,0,0,1,.133.456h-4.453a3.058,3.058,0,0,1,.174-.531,2.222,2.222,0,0,1,.833-1,2.364,2.364,0,0,1,1.283-.333,2.008,2.008,0,0,1,2.029,1.406m3.744,1.542a7.1,7.1,0,0,0-.691-3.2,5.049,5.049,0,0,0-2.035-2.165,6.382,6.382,0,0,0-3.245-.777,5.722,5.722,0,0,0-3.084.851,5.957,5.957,0,0,0-2.141,2.295,6.623,6.623,0,0,0-.771,3.17,6.3,6.3,0,0,0,.808,3.14,6.114,6.114,0,0,0,2.251,2.295,6.254,6.254,0,0,0,3.257.857,6.9,6.9,0,0,0,2.437-.413,6.361,6.361,0,0,0,1.968-1.166L246.1,34.636a7.341,7.341,0,0,1-1.336.216A3.006,3.006,0,0,1,243.3,34.5a2.55,2.55,0,0,1-1.018-1.036,2.686,2.686,0,0,1-.155-.339h7.859c0-.008.07-.524.086-.734s.025-.426.025-.648"
              transform="translate(-186.132 -20.009)"
              fill={color}
            />
          </g>
        </g>
        <rect
          id="Rectangle_139"
          data-name="Rectangle 139"
          width="3.877"
          height="17.604"
          transform="translate(38.269 0.181)"
          fill={color}
        />
      </g>
      <g id="Group_2286" data-name="Group 2286" transform="translate(20 84.34)">
        <g id="Group_2285" data-name="Group 2285" clipPath="url(#clip-path-3)">
          <path
            id="Path_2520"
            data-name="Path 2520"
            d="M387.849,153.529V142.3a.376.376,0,1,0-.752,0v11.228a.376.376,0,0,0,.752,0"
            transform="translate(-375.816 -137.789)"
            fill={color}
            stroke={color}
            strokeWidth="0.3"
          />
          <path
            id="Path_2521"
            data-name="Path 2521"
            d="M22.938,1.686A9.757,9.757,0,0,1,20.1.98,13.615,13.615,0,0,0,15.708,0a4.492,4.492,0,0,0-4.051,2.174,4.569,4.569,0,0,0-.536-.718A4.583,4.583,0,0,0,7.606,0a13.615,13.615,0,0,0-4.4.981,9.758,9.758,0,0,1-2.834.706A.376.376,0,0,0,0,2.062V17.208a.376.376,0,0,0,.376.376,10.2,10.2,0,0,0,3.08-.748,13.045,13.045,0,0,1,4.149-.94,3.829,3.829,0,0,1,2.96,1.211.376.376,0,0,0,.555-.507,4.583,4.583,0,0,0-3.515-1.456,13.615,13.615,0,0,0-4.4.981,12.549,12.549,0,0,1-2.458.684V2.42a12.435,12.435,0,0,0,2.7-.729A13.044,13.044,0,0,1,7.606.751a3.71,3.71,0,0,1,3.692,2.4.376.376,0,0,0,.719,0,3.746,3.746,0,0,1,3.692-2.4,13.044,13.044,0,0,1,4.149.94,12.432,12.432,0,0,0,2.7.729V16.81a12.553,12.553,0,0,1-2.458-.684,13.616,13.616,0,0,0-4.4-.981A4.583,4.583,0,0,0,12.193,16.6a.376.376,0,1,0,.555.507,3.829,3.829,0,0,1,2.96-1.211,13.044,13.044,0,0,1,4.149.94,10.2,10.2,0,0,0,3.08.748.376.376,0,0,0,.376-.376V2.062a.376.376,0,0,0-.376-.376"
            transform="translate(0 0.001)"
            fill={color}
            stroke={color}
            strokeWidth="0.3"
          />
          <path
            id="Path_2522"
            data-name="Path 2522"
            d="M22.938,573.027a9.757,9.757,0,0,1-2.834-.706,13.616,13.616,0,0,0-4.4-.981,4.607,4.607,0,0,0-3.4,1.333H11a4.607,4.607,0,0,0-3.4-1.333,13.615,13.615,0,0,0-4.4.981,9.757,9.757,0,0,1-2.834.706.376.376,0,1,0,0,.752,10.2,10.2,0,0,0,3.08-.748,13.044,13.044,0,0,1,4.149-.94,3.829,3.829,0,0,1,2.96,1.211.376.376,0,0,0,.278.122H12.47a.377.377,0,0,0,.278-.122,3.829,3.829,0,0,1,2.96-1.211,13.044,13.044,0,0,1,4.149.94,10.2,10.2,0,0,0,3.08.748.376.376,0,0,0,0-.752"
            transform="translate(0 -554.69)"
            fill={color}
            stroke={color}
            strokeWidth="0.3"
          />
        </g>
      </g>
    </g>
  </svg>
)

export const ActiveIndicatorIcon = ({ color = "#01214A" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="105.043"
    height="41.808"
    viewBox="0 0 105.043 41.808"
  >
    <path
      id="Path_1500"
      data-name="Path 1500"
      d="M6023.118,1737.541a52.537,52.537,0,0,1,51.425,41.808h2.234a52.536,52.536,0,0,1,51.384-41.632Z"
      transform="translate(-6023.118 -1737.541)"
      fill={color}
    />
  </svg>
)

export const MobileActiveIndicatorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="31.841"
    viewBox="0 0 80 31.841"
  >
    <path
      id="Path_2477"
      data-name="Path 2477"
      d="M6023.118,1737.541a40.012,40.012,0,0,1,39.165,31.841h1.7a40.01,40.01,0,0,1,39.134-31.707Z"
      transform="translate(6103.118 1769.382) rotate(180)"
      fill="#005a9a"
    />
  </svg>
)

export const SearchIcon = ({ color = "#01214A" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35.419"
    height="34.913"
    viewBox="0 0 35.419 34.913"
    style={{ width: "27px", height: "27px" }}
  >
    <g
      id="Group_105"
      data-name="Group 105"
      transform="translate(-478.004 227.313) rotate(-45)"
    >
      <g
        id="Ellipse_10"
        data-name="Ellipse 10"
        transform="translate(488 188)"
        fill="none"
        stroke={color}
        strokeWidth="2"
      >
        <circle cx="10.735" cy="10.735" r="10.735" stroke="none" />
        <circle cx="10.735" cy="10.735" r="9.735" fill="none" />
      </g>
      <line
        id="Line_9"
        data-name="Line 9"
        y2="15.744"
        transform="translate(499.092 208.753)"
        fill="none"
        stroke={color}
        strokeWidth="5"
      />
      <path
        id="Path_77"
        data-name="Path 77"
        d="M5600.352,201.5s-.024,5.316,5.034,6.919"
        transform="translate(-5107.806 -3.838)"
        fill="none"
        stroke={color}
        strokeWidth="2"
      />
    </g>
  </svg>
)
