// @ts-check

import React, { Fragment, useMemo, useState } from "react"
import { Link as GatsbyLink } from "gatsby"
import {
  Box,
  HStack,
  Text,
  Link,
  List,
  ListItem,
  ListIcon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react"
import { ArrowForwardIcon } from "@chakra-ui/icons"
import CategorySVGSwitch from "../../../../../config/svgs"
import { order } from "../../../../../config/categories"
import { Header } from "./components"
import { desktopNavBgColor } from "./desktop-nav-constants"
import { formatFunnelUri } from "../../../../utils/format-funnel-uri"
import { PlusIcon } from "../../../components/PlusIcon"
import { MinusIcon } from "../../../components/MinusIcon"

/**
 * @typedef {{uBildungWissenschaftUndArbeit: string[];uEnergieGebaeude: string[];uKlimaschutzKlimawandelanpassung: string[];uMenschenImLand: string[];uNachhaltigkeitVorbildLand: string[];uRaumordnungMobilitaet: string[];teaserText: string;jahr: number;kategorie: string[];}} FixeElementeTop
 */

/**
 * @typedef {{fixeElementeTop: FixeElementeTop;}} PageBuilder
 */

/**
 * @typedef {{id: string;title: string;uri: string;pagebuilder: PageBuilder;}} Page
 */

/**
 * @param {object} props
 * @param {Page[]} props.articles - The articles object.
 * @param {string} props.type - The type of the menu.
 * @param {boolean} props.disableYearSelect - Whether to disable the year select.
 */
const GroupByCategoryMenu = ({ articles, type, disableYearSelect = false }) => {
  const years = [
    ...new Set(articles.map(item => item.pagebuilder.fixeElementeTop.jahr)),
  ]
  const sortedYears = years.length > 1 ? years.sort((a, b) => b - a) : years

  const [currentYear, setCurrentYear] = useState(years[0])

  const { columnA, columnB } = useMemo(() => {
    const categorizedData = articles.reduce((acc, item) => {
      item.pagebuilder.fixeElementeTop.kategorie.forEach(cat =>
        acc[cat] ? acc[cat].push(item) : (acc[cat] = [item])
      )

      return acc
    }, {})

    const t = Object.entries(categorizedData).sort(
      (a, b) => order[a[0]] - order[b[0]]
    )

    const half = Math.round(t.length / 2)

    const columnA = t.slice(0, half)
    const columnB = t.slice(half)

    return { columnA, columnB }
  }, [articles])

  return (
    <Box
      height="100%"
      w="100%"
      backgroundColor={desktopNavBgColor}
      backdropFilter="blur(5px)"
    >
      {!disableYearSelect && (
        <Header
          years={sortedYears}
          currentYear={currentYear}
          setCurrentYear={setCurrentYear}
        />
      )}
      <Accordion
        allowToggle
        allowMultiple
        sx={{
          maxHeight: "80vh",
          overflowY: "scroll",
        }}
      >
        <HStack
          spacing={0}
          wrap="nowrap"
          align="flex-start"
          h="100%"
          position="relative"
          py={{
            base: 4,
            lg: 8,
          }}
          px={{
            base: 4,
            lg: 8,
          }}
          pt={{
            base: 0,
            lg: 0,
          }}
        >
          <ColumnContainer>
            {columnA.map(([kategorie, pages]) => (
              <ColumnItem
                key={kategorie}
                kategorie={kategorie}
                pages={pages}
                currentYear={currentYear}
                type={type}
                disableYearSelect={disableYearSelect}
              />
            ))}
          </ColumnContainer>
          <Box
            sx={{
              position: "absolute",
              left: "50%",
              top: 0,
              bottom: "2rem",
              zIndex: 100,
              // height: "100%",
              backgroundColor: "#fff",
              transform: "translateX(-50%)",
              width: "1px",
              margin: "2px",
              alignSelf: "stretch",
              display: "flex",
            }}
          />
          <ColumnContainer>
            {columnB.map(([kategorie, pages]) => (
              <ColumnItem
                key={kategorie}
                kategorie={kategorie}
                pages={pages}
                currentYear={currentYear}
                type={type}
                disableYearSelect={disableYearSelect}
              />
            ))}
          </ColumnContainer>
        </HStack>
      </Accordion>
    </Box>
  )
}

const ColumnContainer = ({ children, sx = {} }) => (
  <Box
    sx={{
      overflow: "hidden",

      // FLEX
      width: "100%",
      display: "flex",
      flexDirection: "column",
      // alignItems: "flex-start",

      ...(sx || {}),
    }}
  >
    {children}
  </Box>
)

/**
 * @param {object} props
 * @param {number} props.currentYear - The current year.
 * @param {string} props.kategorie - The category.
 * @param {Page[]} props.pages
 * @param {string} props.type - The type.
 * @param {boolean} props.disableYearSelect - Whether to disable the year select.
 */
const ColumnItem = ({
  currentYear,
  kategorie: activeKategorie,
  pages,
  type,
  disableYearSelect = false,
}) => {
  const filtered = disableYearSelect
    ? pages
    : pages.filter(
        item => currentYear === item.pagebuilder.fixeElementeTop.jahr
      )

  const { categorized, withoutSubcategories } = useMemo(() => {
    const f = filtered.map(item => {
      let subKategorien = []

      if (
        activeKategorie === "Klimaschutz & Klimawandelanpassung" &&
        item.pagebuilder.fixeElementeTop.kategorie.includes(
          "Klimaschutz & Klimawandelanpassung"
        )
      ) {
        subKategorien =
          item.pagebuilder.fixeElementeTop.uKlimaschutzKlimawandelanpassung ??
          []
      }

      if (
        activeKategorie === "Energie" &&
        item.pagebuilder.fixeElementeTop.kategorie.includes("Energie")
      ) {
        subKategorien = item.pagebuilder.fixeElementeTop.uEnergieGebaeude ?? []
      }

      if (
        activeKategorie === "Raumordnung & Mobilität" &&
        item.pagebuilder.fixeElementeTop.kategorie.includes(
          "Raumordnung & Mobilität"
        )
      ) {
        subKategorien =
          item.pagebuilder.fixeElementeTop.uRaumordnungMobilitaet ?? []
      }

      if (
        activeKategorie === "Menschen im Land" &&
        item.pagebuilder.fixeElementeTop.kategorie.includes("Menschen im Land")
      ) {
        subKategorien = item.pagebuilder.fixeElementeTop.uMenschenImLand ?? []
      }

      if (
        activeKategorie === "Bildung, Wissenschaft & Arbeit" &&
        item.pagebuilder.fixeElementeTop.kategorie.includes(
          "Bildung, Wissenschaft & Arbeit"
        )
      ) {
        subKategorien =
          item.pagebuilder.fixeElementeTop.uBildungWissenschaftUndArbeit ?? []
      }

      if (
        activeKategorie === "Nachhaltigkeit, Vorbild Land" &&
        item.pagebuilder.fixeElementeTop.kategorie.includes(
          "Nachhaltigkeit, Vorbild Land"
        )
      ) {
        subKategorien =
          item.pagebuilder.fixeElementeTop.uNachhaltigkeitVorbildLand ?? []
      }

      return {
        ...item,
        subKategorien,
      }
    })

    const withSubcategories = f.filter(
      item => (item.subKategorien ?? []).length > 0
    )
    const withoutSubcategories = f.filter(
      item => (item.subKategorien ?? []).length < 1
    )

    const categorizedData = withSubcategories.reduce((acc, item) => {
      const subcategories = item.subKategorien ?? []

      subcategories.forEach(cat => {
        const catKey = cat || "-"

        acc[catKey] ? acc[catKey].push(item) : (acc[catKey] = [item])
      })

      return acc
    }, {})

    return {
      categorized: Object.entries(categorizedData),
      withoutSubcategories,
    }
  }, [filtered, activeKategorie])

  if (filtered.length < 1) {
    return null
  }

  return (
    <Box
      outline="1px solid white"
      py={{
        base: 2,
        lg: 4,
      }}
      pl={{
        base: 0,
        lg: 4,
      }}
      pr={{
        base: 2,
        lg: 4,
      }}
    >
      <AccordionItem
        sx={{
          border: "none",
        }}
      >
        {({ isExpanded: isParentExpanded }) => (
          <>
            <HStack justifyContent="space-between" alignItems="center" w="100%">
              <Box
                w="50px"
                h="50px"
                flex="0 0 auto"
                flexShrink={0}
                ml={4}
                mr={2}
              >
                {CategorySVGSwitch(activeKategorie)}
              </Box>
              <Text
                flexGrow={1}
                display="block"
                as={GatsbyLink}
                color="white"
                fontSize="lg"
                align="left"
                lineHeight="1.4rem"
                wordBreak="break-word"
                to={formatFunnelUri(
                  type,
                  disableYearSelect ? undefined : currentYear,
                  activeKategorie
                )}
                _hover={{
                  textDecoration: "underline",
                  textUnderlineOffset: "2px",
                }}
              >
                {activeKategorie}
              </Text>
              <AccordionButton ml="auto" w={"auto"}>
                <Box>
                  {isParentExpanded ? (
                    <MinusIcon color="light" />
                  ) : (
                    <PlusIcon color="light" />
                  )}
                </Box>
              </AccordionButton>
            </HStack>

            <AccordionPanel pb={4}>
              <List spacing={2} pl={10}>
                {!!categorized.length &&
                  categorized.map(([subkategorie, items]) => {
                    return (
                      <AccordionItem
                        key={subkategorie}
                        sx={{
                          border: "none",
                        }}
                      >
                        {({ isExpanded: isChildExpanded }) => {
                          return (
                            <Fragment key={subkategorie}>
                              <AccordionButton>
                                <ListItem
                                  color="white"
                                  fontSize="sm"
                                  lineHeight="1rem"
                                  _focus={{
                                    outline:
                                      "-webkit-focus-ring-color auto 1px",
                                  }}
                                  display="flex"
                                  alignItems="center"
                                  gap={7}
                                  ml={"-2.5rem"}
                                >
                                  <Box display="inline">
                                    {isChildExpanded ? (
                                      <MinusIcon color="light" size={20} />
                                    ) : (
                                      <PlusIcon color="light" size={20} />
                                    )}
                                  </Box>
                                  <Text
                                    color="white"
                                    fontFamily="CocoSharpL-Bold"
                                    sx={
                                      isChildExpanded
                                        ? {
                                            textDecoration: "underline",
                                            textUnderlineOffset: "2px",
                                          }
                                        : {}
                                    }
                                  >
                                    {subkategorie}
                                  </Text>
                                </ListItem>
                              </AccordionButton>
                              <AccordionPanel p={0} pb={4} overflowX="visible">
                                <List spacing={2}>
                                  {items.map(item => (
                                    <ListItem
                                      pl={6}
                                      color="white"
                                      key={item.uri}
                                      fontSize="sm"
                                      lineHeight="1rem"
                                      _focus={{
                                        outline:
                                          "-webkit-focus-ring-color auto 1px",
                                      }}
                                    >
                                      <ListIcon
                                        as={ArrowForwardIcon}
                                        color="white"
                                        ml={"-1.4rem"}
                                      />
                                      <Link as={GatsbyLink} to={item.uri}>
                                        {item.title}
                                      </Link>
                                    </ListItem>
                                  ))}
                                </List>
                              </AccordionPanel>
                            </Fragment>
                          )
                        }}
                      </AccordionItem>
                    )
                  })}
                {!!withoutSubcategories.length &&
                  withoutSubcategories.map(item => (
                    <ListItem
                      color="white"
                      key={item.uri}
                      fontSize="sm"
                      lineHeight="1rem"
                      _focus={{
                        outline: "-webkit-focus-ring-color auto 1px",
                      }}
                    >
                      <ListIcon
                        as={ArrowForwardIcon}
                        color="white"
                        ml={"-1.4rem"}
                      />
                      <Link as={GatsbyLink} to={item.uri}>
                        {item.title}
                      </Link>
                    </ListItem>
                  ))}
              </List>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Box>
  )
}

export default GroupByCategoryMenu
