// @ts-check

import React, { useState } from "react"
import { Link as GatsbyLink } from "gatsby"
import {
  VStack,
  Text,
  HStack,
  Box,
  Flex,
  Stack,
  Collapse,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react"
import { order } from "../../../../../config/categories"
import { Header } from "./components"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { MinusIcon } from "../../../components/MinusIcon"
import { PlusIcon } from "../../../components/PlusIcon"

const MobileBlickeMenu = ({ data }) => {
  // Create array with unique years of all articles
  const years = [
    ...new Set(data.map(item => item.pagebuilder.fixeElementeTop.jahr)),
  ]

  const sortedYears = years.length > 1 ? years.sort((a, b) => b - a) : years

  const [currentYear, setCurrentYear] = useState(years[0])

  // Filter data by currently selected year and sort by category order defined in user config
  const filteredData = data
    .filter(a => a.pagebuilder.fixeElementeTop.jahr === currentYear)
    .filter(a => a.pagebuilder.fixeElementeTop.kategorie !== null)
    .sort(
      (a, b) =>
        order[a.pagebuilder.fixeElementeTop.kategorie[0]] -
        order[b.pagebuilder.fixeElementeTop.kategorie[0]]
    )

  return (
    <>
      <Header
        years={sortedYears}
        currentYear={currentYear}
        setCurrentYear={setCurrentYear}
      />
      <VStack
        w="100%"
        spacing={0}
        align="normal"
        pt={4}
        pb={12}
        overflowY="scroll"
        h="calc(100vh - 12rem)"
        px={4}
      >
        <BlickeMagazin />

        {filteredData.map(item => {
          return <MobileNavItem key={item.id || item.title} item={item} />
        })}
      </VStack>
    </>
  )
}

const BlickeMagazin = () => {
  return (
    <Flex
      direction="row"
      flexWrap="nowrap"
      bg="dark_blue"
      align="center"
      as={GatsbyLink}
      to="/blicke"
      role="group"
    >
      <Box w={imageWidth} h={"90px"} flex="0 0 auto" flexShrink={0}>
        <StaticImage
          src="../../desktop/submenus/magazin-blicke.jpg"
          alt="Blicke Magazin"
          style={{
            height: "100%",
          }}
        />
      </Box>
      <Box pl={2}>
        <Text
          color="white"
          textAlign="left"
          fontFamily="kepler-std-display"
          fontSize="1.375rem"
          lineHeight="1.6rem"
          _groupHover={{
            textDecoration: "underline",
            textUnderlineOffset: "2px",
          }}
        >
          Alle Artikel im Überblick
        </Text>
        <Text color="white" as="p" textAlign="left">
          <Text
            as="span"
            color="secondary"
            display="inline"
            fontFamily="CocoSharp-Bold"
          >
            blicke
          </Text>{" "}
          das Begleitmagazin zum Bericht mit spannenden „Einblicken“.
        </Text>
      </Box>
    </Flex>
  )
}

const imageWidth = "80px"

const MobileNavItem = ({ item }) => {
  const { isOpen, onToggle } = useDisclosure()

  const teaser = item.pagebuilder.fixeElementeTop.teaserText

  const image = getImage(item.featuredImage.node.localFile)

  return (
    <>
      <Stack spacing={0} borderBottom="1px solid white">
        <Flex
          py={2}
          justify="space-between"
          align={"center"}
          _hover={{
            textDecoration: "none",
          }}
          width="100%"
          flex={1}
        >
          <HStack>
            <Box w={imageWidth} h="50px" flex="0 0 auto" flexShrink={0} mr={2}>
              {image && (
                <GatsbyImage
                  image={image}
                  alt={item.featuredImage.node.altText}
                  objectFit="cover"
                  style={{
                    height: "100%",
                  }}
                />
              )}
            </Box>
            <Text
              color="white"
              textAlign="left"
              fontFamily="kepler-std-display"
              fontSize="1.375rem"
              lineHeight="1.6rem"
              as={GatsbyLink}
              to={item.uri}
              _hover={{
                textDecoration: "underline",
                textUnderlineOffset: "2px",
              }}
            >
              {item.title}
            </Text>
          </HStack>
          <Box>
            <IconButton
              onClick={onToggle}
              aria-label="Toggle collapse"
              variant={"link"}
            >
              {isOpen ? <MinusIcon color="dark" /> : <PlusIcon color="dark" />}
            </IconButton>
          </Box>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <HStack wrap="nowrap" mb={2}>
            <Box w={imageWidth} flex="0 0 auto" flexShrink={0} mr={2} />
            <Text color="white" textAlign="left">
              {teaser}
            </Text>
          </HStack>
        </Collapse>
      </Stack>
    </>
  )
}

export default MobileBlickeMenu
