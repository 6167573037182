import React from "react"

import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby"

import Logo from "../../static/logo.svg"

import {
  Box,
  Flex,
  Stack,
  Link,
  useColorModeValue,
  useBreakpointValue,
} from "@chakra-ui/react"

import DesktopNav from "./navigation/desktop/DesktopNav"
import SearchInputDesktop from "./navigation/desktop/SearchInputDesktop"

import MobileNav from "./navigation/mobile/MobileNav"
import SearchInputMobile from "./navigation/mobile/SearchInputMobile"
import { MobileLogo } from "./components/MobileLogo"

export const NAV_ITEMS = ["Blicke", "Bereiche", "Daten", "Projekte"]

export default function Navigation({ pageType }) {
  const data = useStaticQuery(query)

  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <>
      <Box
        as="nav"
        borderBottom={{ base: 0, md: 1 }}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
      >
        <Box maxW="7xl" mx="auto">
          {/* DESKTOP */}
          <Flex
            color={useColorModeValue("gray.600", "white")}
            minH={"80px"}
            pt={{ base: 4, md: "48px" }}
            pb={4}
            px={{ base: 4 }}
            display={{ base: "none", md: "flex" }}
            justifyContent="space-between"
          >
            <Link
              as={GatsbyLink}
              to="/"
              marginTop={{ base: 0, md: -7 }}
              mr={2}
              aria-label="Zur Homepage"
              display={{
                base: "none",
                lg: "block",
              }}
            >
              <Logo />
            </Link>
            <Link
              as={GatsbyLink}
              to="/"
              mr={2}
              aria-label="Zur Homepage"
              display={{
                base: "block",
                lg: "none",
              }}
            >
              <MobileLogo />
            </Link>

            <DesktopNav data={data} pageType={pageType} />

            <Stack
              flex={{ base: 1, md: 0 }}
              justify={"flex-end"}
              direction={"row"}
              spacing={6}
            >
              <SearchInputDesktop searchValues={data.search} />
            </Stack>
          </Flex>

          {/* MOBILE */}
          <Flex
            display={{ base: "flex", md: "none" }}
            justify="space-between"
            align="center"
            minH={"90px"}
          >
            <Link
              as={GatsbyLink}
              to="/"
              ml={2}
              pr={{ base: 6, sm: null }}
              my={2}
              aria-label="Zur Homepage"
            >
              <Box sx={{ "> *": { maxWidth: "100%" } }} ml={2} mr={3}>
                <MobileLogo />
              </Box>
            </Link>
            <MobileNav data={data} />
          </Flex>
        </Box>
      </Box>
      <SearchInputMobile searchValues={data.search} />
    </>
  )
}

const query = graphql`
  query Navbar {
    search: localSearchPages {
      index
      store
    }
    bereiche: allWpPage(
      filter: {
        status: { eq: "publish" }
        pagebuilder: { fixeElementeTop: { typ: { name: { eq: "Bereiche" } } } }
      }
    ) {
      nodes {
        id
        title
        uri
        pagebuilder {
          fixeElementeTop {
            teaserText
            jahr
            kategorie
          }
        }
      }
    }
    daten: allWpPage(
      filter: {
        status: { eq: "publish" }
        pagebuilder: { fixeElementeTop: { typ: { name: { eq: "Daten" } } } }
      }
    ) {
      nodes {
        ...NavDatenProjekteFragment
      }
    }
    projekte: allWpPage(
      filter: {
        status: { eq: "publish" }
        pagebuilder: { fixeElementeTop: { typ: { name: { eq: "Projekte" } } } }
      }
    ) {
      nodes {
        ...NavDatenProjekteFragment
      }
    }
    blicke: allWpPage(
      filter: {
        status: { eq: "publish" }
        pagebuilder: { fixeElementeTop: { typ: { name: { eq: "Blicke" } } } }
      }
    ) {
      nodes {
        id
        title
        uri
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
        pagebuilder {
          fixeElementeTop {
            teaserText
            jahr
            kategorie
          }
        }
      }
    }
  }

  fragment NavDatenProjekteFragment on WpPage {
    id
    title
    uri
    pagebuilder {
      fixeElementeTop {
        teaserText
        jahr
        kategorie
        uBildungWissenschaftUndArbeit
        uEnergieGebaeude
        uKlimaschutzKlimawandelanpassung
        uMenschenImLand
        uNachhaltigkeitVorbildLand
        uRaumordnungMobilitaet
      }
    }
  }
`
