import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link as GatsbyLink } from "gatsby"
import { Box, Heading, Text, Link, useMediaQuery } from "@chakra-ui/react"
import { BlickeReiterSvg } from "../../layout/svgs"

const TextBox = ({ title, uri, teaserText, center = true }) => (
  <Box
    position="absolute"
    bottom={0}
    left={{ base: "50%", md: center ? "50%" : 0 }}
    width="calc(100% - 2rem)"
    maxWidth="700px"
    padding={8}
    marginLeft={{ base: null, md: !center && 4 }}
    backgroundColor="white"
    borderWidth="1px"
    borderColor="dark_blue"
    transform="auto"
    translateY="50%"
    translateX={{ base: "-50%", md: center ? "-50%" : "0" }}
  >
    <Heading
      as="h3"
      fontFamily="kepler-std"
      fontSize="1.875rem"
      lineHeight="1.875rem"
      fontWeight="semibold"
      textAlign="center"
    >
      {title}
    </Heading>
    <Box
      width="20px"
      mx="auto"
      my="1rem"
      borderBottom="1px"
      borderColor="#212121"
    />
    <Text textAlign="center" lineHeight="1.3125rem">
      {teaserText}
    </Text>

    <Link
      to={uri}
      as={GatsbyLink}
      _hover={{ textDecoration: "none" }}
      role="group"
      aria-label={`Hier ist der Link zu "${title}"`}
    >
      <Box
        width="170px"
        height="50px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        border="1px"
        borderColor="#212121"
        borderRadius="32px"
        backgroundColor="white"
        _hover={{ backgroundColor: "#E9C542" }}
        mx="auto"
        mt={8}
        mb={"-56px"}
      >
        <Text
          fontFamily="CocoSharpL-Bold"
          textAlign="center"
          fontSize="sm"
          letterSpacing="1px"
          fontWeight="bold"
          pt={1}
        >
          ARTIKEL LESEN
        </Text>
      </Box>
    </Link>

    <Box
      width="100px"
      height="32px"
      position="absolute"
      display="flex"
      justifyContent="center"
      alignItems="center"
      top="-47px"
      left="50%"
      transform="auto"
      translateX={"-50%"}
    >
      <BlickeReiterSvg />
    </Box>
  </Box>
)

const TextCentered = ({ title, uri, teaserText }) => (
  <Box
    position="absolute"
    top="50%"
    left="50%"
    width="950px"
    maxWidth="90%"
    padding={{ base: 0, md: 8 }}
    minHeight="330px"
    transform="auto"
    translateX="-50%"
    translateY={{ base: "-50%", sm: "-40%" }}
  >
    <Heading
      as="h4"
      fontSize={{ base: "1.5rem", sm: "1.9rem", md: "2.5rem" }}
      fontFamily="kepler-std"
      textAlign="center"
    >
      {title}
    </Heading>
    <Box
      width="20px"
      mx="auto"
      my="1rem"
      borderBottom="1px"
      borderColor="#212121"
    />
    <Text textAlign="center">{teaserText?.substring(0, 230)}...</Text>

    <Link to={uri} as={GatsbyLink}>
      <Box
        width="170px"
        height="50px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        border="2px"
        borderColor="#212121"
        borderRadius="32px"
        mx="auto"
        mt={8}
        mb={"-56px"}
      >
        <Text textAlign="center" fontSize={20}>
          Mehr hier
        </Text>
      </Box>
    </Link>
  </Box>
)

const Teaser = ({ data, hintergrundBild, center, textBox = true }) => {
  const [isLargeDesktop] = useMediaQuery("(min-width: 2000px)")
  const [isVeryLargeDesktop] = useMediaQuery("(min-width: 3000px)")

  console.log({
    isLargeDesktop,
  })

  if (!data) {
    return null
  }

  const {
    uri,
    title,
    pagebuilder: {
      fixeElementeTop: { teaserText },
    },
  } = data[0]
  const image = hintergrundBild && getImage(hintergrundBild.localFile)

  return (
    <Box
      as="section"
      mt={12}
      mb={textBox && "200px"}
      width="100vw"
      top={0}
      left="50%"
      right="50%"
      marginLeft="-50vw"
      marginRight="-50vw"
      position="relative"
      // Make sure img shows centered on faces on very large screens
      height={
        isLargeDesktop
          ? textBox
            ? "1000px"
            : "840px"
          : textBox
          ? "700px"
          : "540px"
      }
      sx={{
        "picture img": {
          // Make sure img shows centered on faces on very large screens
          objectPosition: `center ${
            isVeryLargeDesktop ? "-600px" : isLargeDesktop ? "-300px" : "center"
          } !important`,
        },
      }}
    >
      <Box zIndex={1} position="relative" maxW={"7xl"} mx="auto" height="100%">
        {textBox ? (
          <TextBox
            title={title}
            uri={uri}
            teaserText={teaserText}
            center={center}
          />
        ) : (
          <TextCentered title={title} uri={uri} teaserText={teaserText} />
        )}
      </Box>
      <Box
        width="100%"
        height="100%"
        position="absolute"
        top={0}
        left={0}
        backgroundColor={!textBox && "#DDCFEE"}
      >
        {hintergrundBild ? (
          <GatsbyImage
            style={{ width: "100%", height: "100%" }}
            objectFit="cover"
            image={image}
            alt={hintergrundBild.altText}
          />
        ) : null}
      </Box>
    </Box>
  )
}

export default Teaser
