// @ts-check

import React, { useState } from "react"
import { Link as GatsbyLink } from "gatsby"
import { Box, Text, Flex, Grid, Link, VStack, Center } from "@chakra-ui/react"
import { order } from "../../../../../config/categories"
import { Header } from "./components"
import { desktopNavBgColor } from "./desktop-nav-constants"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

/**
 * Represents a Blicke menu.
 * @param {object} props
 * @param {object} props.data - The data object.
 */
const BlickeMenu = ({ data }) => {
  // Create array with unique years of all articles
  const years = [
    ...new Set(data.map(item => item.pagebuilder.fixeElementeTop.jahr)),
  ]
  const sortedYears = years.length > 1 ? years.sort((a, b) => b - a) : years

  const [currentYear, setCurrentYear] = useState(years[0])

  // Filter data by currently selected year and sort by category order defined in user config
  const filteredData = data
    .filter(a => a.pagebuilder.fixeElementeTop.jahr === currentYear)
    .filter(a => a.pagebuilder.fixeElementeTop.kategorie !== null)
    .sort(
      (a, b) =>
        order[a.pagebuilder.fixeElementeTop.kategorie[0]] -
        order[b.pagebuilder.fixeElementeTop.kategorie[0]]
    )

  return (
    <Box height="100%" w="100%" backgroundColor={desktopNavBgColor}>
      <Header
        years={sortedYears}
        currentYear={currentYear}
        setCurrentYear={setCurrentYear}
      />

      <Flex
        minH="200px"
        maxH="80vh"
        overflowY="scroll"
        align="flex-start"
        flexDirection="row"
        flexWrap="nowrap"
        p={{
          md: 4,
          lg: 8,
        }}
        pt={{
          md: 0,
          lg: 0,
        }}
      >
        <BlickeMagazin />

        <Grid
          templateColumns={{
            base: "repeat(2, 1fr)",
            lg: "repeat(4, 1fr)",
          }}
          gap={0}
          h="auto"
          overflow="hidden"
          borderLeft="1px solid white"
        >
          {filteredData.map(item => {
            return (
              <GridItemContainer key={item.id} uri={item.uri}>
                <BlickeContentType item={item} />
              </GridItemContainer>
            )
          })}
        </Grid>
      </Flex>
    </Box>
  )
}

const BlickeMagazin = () => {
  return (
    <Flex
      direction="column"
      height="100%"
      flexShrink={0}
      as={Link}
      href="/blicke"
      py={4}
      pr={2}
      sx={{
        _hover: {
          textDecoration: "none",
        },
      }}
    >
      <StaticImage
        src={"./magazin-blicke.jpg"}
        alt="Blicke Magazin"
        imgStyle={{
          height: "100%",
        }}
        width={250}
        height={250}
        quality={100}
      />
      <Center
        pb={1}
        sx={{ backgroundColor: "primary" }}
        flexDirection="column"
        py={6}
      >
        <Text
          as="span"
          fontFamily="kepler-std"
          color="white"
          textAlign="center"
          fontSize="1.4375rem"
          lineHeight="1.5rem"
          fontWeight="semibold"
          px={2}
          wordBreak="break-word"
          display="block"
          flex={1}
        >
          Alle Artikel
          <br />
          im Überblick
        </Text>

        <Text as="span" color="white" fontSize="1.4375rem" lineHeight="0.8rem">
          -
        </Text>

        <Text
          as="span"
          color="white"
          textAlign="center"
          fontSize={"md"}
          lineHeight={"1.25rem"}
          px={2}
        >
          <Text as="span" color="secondary" fontWeight="bolder">
            blicke
          </Text>
          <br />
          das Begleitmagazin
          <br />
          zum Bericht
          <br />
          mit spannenden
          <br />
          „Einblicken“.
        </Text>
      </Center>
    </Flex>
  )
}

const GridItemContainer = ({ children, uri, ...props }) => (
  <Flex
    minH="200px"
    outline="1px solid white"
    w="100%"
    _hover={{ backgroundColor: "primary" }}
  >
    <Link
      as={GatsbyLink}
      to={uri}
      w="100%"
      p={2}
      _hover={{ textDecoration: "none" }}
      _focus={{
        opacity: 0.8,
        outline: "-webkit-focus-ring-color auto 3px",
      }}
      {...props}
    >
      {children}
    </Link>
  </Flex>
)

export default BlickeMenu

const BlickeContentType = ({ item }) => {
  const teaser = item.pagebuilder?.fixeElementeTop?.teaserText
  const image = getImage(item.featuredImage?.node?.localFile)

  return (
    <VStack py={2} align="center" justify="flex-start" h="100%">
      {image && (
        <GatsbyImage
          image={image}
          alt={item.featuredImage?.node?.altText}
          style={{
            height: "150px",
          }}
        />
      )}
      <Text
        as="span"
        fontFamily="kepler-std"
        color="white"
        textAlign="center"
        fontSize="1.4375rem"
        lineHeight="1.5rem"
        fontWeight="semibold"
        wordBreak="break-word"
        display="block"
        flex={1}
        dangerouslySetInnerHTML={{
          __html: item.title,
        }}
      />

      <Text as="span" color="white" fontSize="1.4375rem" lineHeight="0.8rem">
        -
      </Text>

      <Text
        as="span"
        color="white"
        textAlign="center"
        fontSize={"md"}
        lineHeight={"1.25rem"}
        px={2}
        noOfLines={3}
        dangerouslySetInnerHTML={{
          __html: teaser,
        }}
      />
    </VStack>
  )
}
