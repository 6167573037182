// @ts-check

import React, { useState } from "react"
import { Link as GatsbyLink } from "gatsby"
import { Box, Text, Flex, Grid, Link, VStack } from "@chakra-ui/react"
import CategorySVGSwitch from "../../../../../config/svgs"
import { order } from "../../../../../config/categories"
import { Header } from "./components"
import { desktopNavBgColor } from "./desktop-nav-constants"

/**
 * Represents a Bereiche menu.
 * @param {object} props
 * @param {object} props.data - The data object.
 */
const BereicheMenu = ({ data }) => {
  // Create array with unique years of all articles
  const years = [
    ...new Set(data.map(item => item.pagebuilder.fixeElementeTop.jahr)),
  ]
  const sortedYears = years.length > 1 ? years.sort((a, b) => b - a) : years

  const [currentYear, setCurrentYear] = useState(years[0])

  // Filter data by currently selected year and sort by category order defined in user config
  const filteredData = data
    .filter(a => a.pagebuilder.fixeElementeTop.jahr === currentYear)
    .filter(a => a.pagebuilder.fixeElementeTop.kategorie !== null)
    .sort(
      (a, b) =>
        order[a.pagebuilder.fixeElementeTop.kategorie[0]] -
        order[b.pagebuilder.fixeElementeTop.kategorie[0]]
    )

  return (
    <Box height="100%" w="100%" backgroundColor={desktopNavBgColor}>
      <Header
        years={sortedYears}
        currentYear={currentYear}
        setCurrentYear={setCurrentYear}
      />

      <Flex
        minH="200px"
        maxH="80vh"
        overflowY="scroll"
        align="flex-start"
        p={{
          md: 4,
          lg: 8,
        }}
        pt={{
          md: 0,
          lg: 0,
        }}
      >
        <Grid
          templateColumns="repeat(4, 1fr)"
          gap={0}
          h="auto"
          overflow="hidden"
        >
          {filteredData.map(item => {
            return (
              <GridItemContainer key={item.id} uri={item.uri}>
                <BereicheContentType item={item} />
              </GridItemContainer>
            )
          })}
        </Grid>
      </Flex>
    </Box>
  )
}

const GridItemContainer = ({ children, uri, ...props }) => (
  <Flex
    minH="200px"
    outline="1px solid white"
    w="100%"
    _hover={{ backgroundColor: "primary" }}
  >
    <Link
      as={GatsbyLink}
      to={uri}
      w="100%"
      p={2}
      _hover={{ textDecoration: "none" }}
      _focus={{
        opacity: 0.8,
        outline: "-webkit-focus-ring-color auto 3px",
      }}
      {...props}
    >
      {children}
    </Link>
  </Flex>
)

export default BereicheMenu

const BereicheContentType = ({ item }) => {
  const { kategorie } = item.pagebuilder.fixeElementeTop

  return (
    <VStack align="center" justify="center" h="100%">
      <Box w="70px" h="70px">
        {kategorie[0] && CategorySVGSwitch(kategorie[0])}
      </Box>

      <Text
        as="span"
        color="white"
        textAlign="center"
        fontSize={"1.375rem"}
        lineHeight={"1.6rem"}
        px={2}
        wordBreak="break-word"
      >
        {item.title}
      </Text>
    </VStack>
  )
}
